import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import TimePicker from 'react-time-picker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-time-picker/dist/TimePicker.css';
import axios from 'axios';
import { Button } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import '@mui/material/CircularProgress';
import 'react-toastify/dist/ReactToastify.css';

const Meeting = () => {
  const [meetings, setMeetings] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [meetingTime, setMeetingTime] = useState('10:00');
  const [scheduleWith, setScheduleWith] = useState('');
  const [notes, setNotes] = useState('');
  const [title, setTitle] = useState('');
  const [location, setLocation] = useState('');
  const [meetingDate, setMeetingDate] = useState(new Date());
  const [meetingsOfSelectedDate, setMeetingsOfSelectedDate] = useState([]);
  const [isCreatingMeeting, setIsCreatingMeeting] = useState(false);
  const [loading, setLoading] = useState(true);

  const isSameDate = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  const handleCreateMeeting = async () => {
    setIsCreatingMeeting(true);
    const newMeeting = {
      title,
      location,
      date: meetingDate,
      time: meetingTime,
      scheduleWith,
      notes,
    };

    try {
      let response = await axios.post(
        'https://api.triphog.net/api/v1/meeting/create-meeting',
        newMeeting,
        { headers: { Authorization: localStorage.getItem('token') } }
      );
      if (response.data.success) {
        toast.success('Meeting Successfully Created!', {
          position: 'top-center',
        });
        getMeetings();
      } else {
        toast.error('There Was An Error While Creating Meeting!', {
          position: 'top-center',
        });
      }
    } catch (error) {
      toast.error('Failed to create meeting. Please try again.', {
        position: 'top-center',
      });
    } finally {
      setIsCreatingMeeting(false);
    }
  };

  const getMeetings = async () => {
    try {
      let response = await axios.get(
        'https://api.triphog.net/api/v1/meeting/getmeetings',
        { headers: { Authorization: localStorage.getItem('token') } }
      );
      if (response.data.success) {
        setMeetings(response.data.meetings);

        let _selectedDateMeetings = response.data.meetings.filter((meeting) => {
          return isSameDate(new Date(meeting.date), selectedDate);
        });
        setMeetingsOfSelectedDate(_selectedDateMeetings);
        setLoading(false);
      } else {
        setLoading(true);
      }
    } catch (error) {
      toast.error('Failed to fetch meetings. Please try again.', {
        position: 'top-center',
      });
      setLoading(false);
    }
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);

    let _selectedDateMeetings = meetings.filter((meeting) => {
      return isSameDate(new Date(meeting.date), date);
    });
    setMeetingsOfSelectedDate(_selectedDateMeetings);
  };

  useEffect(() => {
    getMeetings();
  }, []);

  return (
    <div className="flex flex-col lg:flex-row h-screen w-screen p-4 meeting_page" >
      {/* Left Side - Meeting Form */}
      <div className="lg:w-1/3 bg-gray-100 p-4 lg:p-6 rounded-lg shadow-lg mb-6 lg:mb-0">
        <h2 className="text-xl lg:text-2xl font-bold mb-4">Schedule a Meeting</h2>
        <div className="mb-4">
          <label className="block text-gray-700">Title</label>
          <input
            type="text"
            className="w-full p-2 border rounded-lg"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Location</label>
          <input
            type="text"
            className="w-full p-2 border rounded-lg"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Date</label>
          <DatePicker
            selected={meetingDate}
            onChange={(date) => setMeetingDate(date)}
            className="w-full p-2 border rounded-lg"
            calendarClassName="react-datepicker__day--selected:bg-purple-600"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Time</label>
          <TimePicker
            onChange={(timeValue)=>{
              console.log("Value Of Time",timeValue)
              setMeetingTime(timeValue)}}
            value={meetingTime}
            className="w-full p-2 border rounded-lg"
            clockClassName="react-time-picker__clock__hand--purple"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Schedule With</label>
          <input
            type="text"
            className="w-full p-2 border rounded-lg"
            value={scheduleWith}
            onChange={(e) => setScheduleWith(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Notes</label>
          <textarea
            className="w-full p-2 border rounded-lg"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          ></textarea>
        </div>
        <button
        style={{color:"white",backgroundColor:"#30325E"}}
        variant='contained'
          className=" text-white py-2 px-4 rounded-lg w-full flex justify-center items-center"
          onClick={handleCreateMeeting}
          disabled={isCreatingMeeting}
        >
          {isCreatingMeeting ? (
            <CircularProgress size={24} style={{ color: 'white' }} />
          ) : (
            'Create Meeting'
          )}
        </button>
      </div>

      {/* Right Side - Calendar & Meetings */}
      <div className="h-full lg:w-1/2 lg:ml-6 ">
        <div className="bg-gray-100 p-4 lg:p-6 rounded-lg shadow-lg h-screen">
          <h2 className="text-xl lg:text-2xl font-bold mb-4">Upcoming Meetings</h2>
          <div className="mb-6 upcoming_meeting_div">
            <DatePicker
              selected={selectedDate}
              onChange={handleDateClick}
              inline
              className="react-datepicker__day--selected:bg-[#30325E] text-[#30325E] calendar_view"
            />
          </div>
          <h2 className="text-lg lg:text-xl font-bold mb-2">
            Meetings on {selectedDate.toDateString()}
          </h2>
          <div className="overflow-y-auto max-h-96 mb-8">
            {loading ? (
              <div className="flex justify-center">
                <CircularProgress style={{ color: '#30325E' }} />
              </div>
            ) : meetingsOfSelectedDate.length > 0 ? (
              meetingsOfSelectedDate.map((meeting) => (
                <div key={meeting._id} className="bg-white p-4 mb-4 rounded-lg shadow">
                  <h3 className="text-lg font-bold">{meeting.title}</h3>
                  <p>
                    {meeting.scheduleWith} at {meeting.time} in {meeting.location}
                  </p>
                  <p className="text-sm text-gray-600">{meeting.notes}</p>
                </div>
              ))
            ) : (
              <p>No meetings scheduled for this date.</p>
            )}
          </div>
<ToastContainer/>
          <h2 className="text-lg lg:text-xl font-bold mb-2">All Meetings</h2>
          <div className="overflow-y-auto max-h-96 bg-gray-100">
            {loading ? (
              <div className="flex justify-center">
                <CircularProgress style={{ color: '#30325E' }} />
              </div>
            ) : meetings.length > 0 ? (
              meetings.map((meeting) => (
                <div key={meeting._id} className="bg-white p-4 mb-4 rounded-lg shadow">
                  <h3 className="text-lg font-bold">{meeting.title}</h3>
                  <p>
                    {meeting.scheduleWith} at {meeting.time} in {meeting.location}
                  </p>
                  <p className="text-sm text-gray-600">{meeting.notes}</p>
                </div>
              ))
            ) : (
              <p>No meetings available.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Meeting;
