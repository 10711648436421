import React, { useState, useContext } from "react";
import { CiLock } from "react-icons/ci";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { MdOutlineEmail } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { useAdminLogin } from "../../../Providers/Admin/AdminLoginProvider"; // Adjust the import path as needed

import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const LoginPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSubAdmin, setIsSubAdmin] = useState(false); // New state for role

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleRole = () => {
    setIsSubAdmin(!isSubAdmin);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Admin Login");

    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `https://api.triphog.net/api/v1/${isSubAdmin ? 'user' : 'admin'}/login`, // Modify API path based on role
        { email, password }
      );
      if (isSubAdmin) {
        if (response.data.success) {
          console.log(response.data);
          localStorage.setItem("token", response.data.token);
          window.location.href = "/admin";
        } else {
          setError("Login failed. Please check your credentials.");
          return false; // Return false for failed login
        }
      } else { }
      console.log(response.data);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("adminId", response.data.adminId);
      localStorage.setItem("adminEMailAddress", response.data.adminEmail);
      if (response.data.success) {
        
        if (response.data.isOnHold) {
          toast.warn("Your Account Is On Hold Because " + response.data.warningMsg);
        } else {
          window.location.href = "/admin";
        }
      } else {
        setError("Login failed. Please check your credentials.");
        return false; // Return false for failed login
      }
    } catch (err) {
      console.error("Login error:", err);
      return false; // Return false for failed login due to error
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col laptop:flex-row overflow-scroll laptop:overflow-hidden" style={{ height: "100vh" }}>
  {/* Left Section with bg-deepBlue */}
  <div className="laptop:flex-1 hidden laptop:flex flex-col bg-deepBlue text-white" style={{ height: "100vh" }}>
    <div className="p-4">
      <h1 className="text-4xl">TRIPHOG</h1>
    </div>
    <ToastContainer />
    <div className="flex flex-1 w-full justify-center items-center">
      <div className="w-64 h-64 bg-[#252749] rounded-full flex items-center justify-center">
        <img src={require("./appimage.jpeg")} className="rounded-full" />
      </div>
    </div>
  </div>

  {/* Right Section */}
  <div className="flex-1 flex flex-col justify-center items-center mt-[-60px] mb-[-20px]">
    <div>
      <img src="/uploads/login.jpg" alt="login" className="laptop:hidden" />
    </div>
    <form
      onSubmit={handleSubmit}
      className="bg-white p-8 rounded-lg laptop:w-[75%] w-full"
    >
      <h2 className="laptop:text-2xl text-lg font-bold mb-6 text-center">
        Welcome
      </h2>

      {/* Toggle Button for Admin/SubAdmin */}
      <div className="mb-6 text-center">
        <button
          type="button"
          className={`px-4 py-2 rounded-full mr-2 ${!isSubAdmin ? "bg-deepBlue text-white" : "bg-gray-200 text-black"}`}
          onClick={toggleRole}
        >
          Admin
        </button>
        <button
          type="button"
          className={`px-4 py-2 rounded-full ${isSubAdmin ? "bg-deepBlue text-white" : "bg-gray-200 text-black"}`}
          onClick={toggleRole}
        >
          SubAdmin
        </button>
      </div>

      {/* Email Input */}
      <div className="laptop:mb-4 mb-8 relative text-lg">
        <input
          type="email"
          placeholder="Email / Username"
          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 laptop:pl-10 pl-14"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <span className="absolute inset-y-0 left-0 flex items-center text-gray-400 pl-3">
          <MdOutlineEmail />
        </span>
      </div>

      {/* Password Input */}
      <div className="laptop:mb-4 mb-8 relative text-lg">
        <input
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 laptop:pl-10 pl-14"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <span className="absolute inset-y-0 left-0 flex items-center text-gray-400 pl-3">
          <CiLock />
        </span>
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
        >
          {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
        </button>
      </div>

      {/* Forgot Password */}
      <div className="mb-4">
        <Link
          to="/admin/forgot-password"
          className="text-indigo-500 hover:text-indigo-700 float-right mb-4 text-sm"
        >
          Forgot password?
        </Link>
      </div>

      {/* Login Button */}
      <button
        type="submit"
        disabled={loading}
        className="w-full bg-deepBlue text-white py-4 rounded-full transition-colors duration-300 text-lg"
      >
        {loading ? "Logging in..." : "Log in"}
      </button>
      {error && <div className="text-red-500 text-center mt-2">{error}</div>}
    </form>
  </div>
</div>

  );
};

export default LoginPage;
