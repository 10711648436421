import React from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { Link, NavLink, useParams } from "react-router-dom";
import styles from "./AdminprofileHeader.module.css";
import { useGetSingleAdminContext } from "../../../../Providers/SuperAdmin/GetSingleAdminProvider";
import { useUpdateAdminContext } from "../../../../Providers/SuperAdmin/UpdateAdminProvider";
import { MdLinkedCamera } from "react-icons/md";

const AdminprofileHeader = () => {
  const { firstName, lastName, photo, plan } = useGetSingleAdminContext();
  console.log("PHOTO URL OF ADMIN", photo);
  const { id } = useParams();
  
  return (
    <div>
      <div className="flex items-center text-m_c laptop:text-lap_d font-[600] justify-between">
        <Link to="/superadmin/subscribers">
          <div className="flex items-center gap-2">
            <IoArrowBackOutline />
            <h2 className="laptop:text-lap_c text-m-c">{`${firstName} ${lastName}`}</h2>
          </div>
        </Link>
        <div className="flex float-end rounded-[7px] text-deepBlue laptop:text-lap_b text-m_b border-deepBlue border-solid border-[1px]">
          <NavLink
            to={`/superadmin/subscribers/admin/${id}`}
            className={({ isActive }) =>
              isActive ? styles.active : styles.notactive
            }
          >
            <h2>Profile</h2>
          </NavLink>
          <NavLink
            to={`/superadmin/subscribers/payment/${id}`}
            className={({ isActive }) =>
              isActive ? styles.active : styles.notactive
            }
          >
            <h2>Payment</h2>
          </NavLink>
        </div>
      </div>
      <div className="grid justify-center mb-[20px] mt-[35px]">
        <div className="w-full rounded-full bg-cover bg-center bg-no-repeat items-center">
          <img
            src={photo} // Directly use the photo URL
            alt=""
            className="w-[141px] h-[141px] rounded-full object-cover" // Ensure the image is circular
          />
        </div>
        <h2 className="text-[#4379EE] laptop:text-lap_b text-m_b my-[10px]">
          Monthly {plan} User
        </h2>
        <Link to={`/superadmin/subscribers/managestatus/${id}`}>
          <button className="bg-deepBlue text-white laptop:text-lap_b text-m_b cursor-pointer rounded-md mx-auto py-[5px] px-[30px] justify-center flex">
            Manage Plan
          </button>
        </Link>
      </div>
    </div>
  );
};

export default AdminprofileHeader;
