import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Avatar, Typography, Input, Snackbar,Alert,IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Card, CardContent, Modal, Button, TextField, Checkbox, FormControlLabel, Menu, MenuItem, Chip, CircularProgress } from '@mui/material';
import { Send, AttachFile, Add, Close, ChatOutlined } from '@mui/icons-material';

import axios from 'axios';
import { io } from 'socket.io-client';
import { useSocket } from '../../SocketContext/SocketContext';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';
import { useChatReceiverId } from '../SuperAdminChatReceiverContext/SuperAdminChatReceiverContext';
const Chat = () => {
  const {receiverId,updateChatReceiverId}=useChatReceiverId()
   const socket=useSocket()
  // console.log("Super Admin Socket Instance",socket.id) 
 const[isChatsLoading,setIsChatsLoading]=useState(true) 
    const [anchorEl, setAnchorEl] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const decodedUser=jwtDecode(localStorage.getItem("superadmintoken"))   
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [filePreview, setFilePreview] = useState(null);
    const[chats,setChats]=useState([])
    const[allChats,setAllChats]=useState([])

    const[text,setText]=useState('')
    const[chatMessages,setChatMessages]=useState([])
  const[admins,setAdmins]=useState([])
    const[receiverName,setReceiverName]=useState("")
    const[isChatLoading,setIsChatLoading]=useState(false)
    const[message,setMessage]=useState("")
    const[severity,setSeverity]=useState("")
    const[isSnackBarOpen,setIsSnackBarOpen]=useState(false)
    const[isUsersModelOpen,setIsUsersModelOpen]=useState(false)
  useEffect(()=>{
    updateChatReceiverId(null)
  },[])
    let getChatMessages=async(ReceiverId)=>{
        try{
        
        setIsChatLoading(true)
        let response=await axios.get(`https://api.triphog.net/api/v1/chat/getmessages/${localStorage.getItem("superAdminId")}/${ReceiverId}`)
        console.log(response.data)
        setChatMessages(response.data.messages)
        setIsChatLoading(false)
        }
        catch(e){
            toast.error("Could Not Fetch Chat Messages!")
            setIsChatLoading(false)
        }
    }
 
    
    const[isOneToOneChat,setIsOneToOneChat]=useState(false)
    
    const[selectedReceiverId,setSelectedReceiverId]=useState(null)
    const getMyChats=async()=>{
        try{
        setIsChatsLoading(true)
        let response=await axios.get("https://api.triphog.net/api/v1/chat/getmychats",{headers:{"Authorization":localStorage.getItem("superadmintoken")}})
        console.log(response.data)
        const sortedChats = response.data.chats.sort((a, b) => {
            const dateA = new Date(a.lastMessage.addedON);
            const dateB = new Date(b.lastMessage.addedON);
            return dateB - dateA; // Sort descending (latest first)
          });
        setChats(sortedChats)
        setAllChats(sortedChats)
        setIsChatsLoading(false)
        }
        catch(e){
            toast.error("Error While Getting Chats")
        }
      
    }
    let messagesEndRef=useRef(null)
    useEffect(() => {
        if (messagesEndRef.current) {
          messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, [chatMessages]);
    let getAllAdmins=async()=>{
        try{
        let response=await axios.get("https://api.triphog.net/api/v1/superadmin/admin")
        console.log(response.data)
        setAdmins(response.data.data)
        }
        catch(e){
            toast.error("Error While Getting Admins")
        }
    }
    let handleCloseUsersModal=()=>{
        setIsUsersModelOpen(false)
    }
    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setIsSnackBarOpen(false);
      };
    
  

    

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

  
   
    const handleUserSelect = (user) => {
        setSelectedUser(user);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setFilePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSendMessage = async() => {
    try{
        if(isOneToOneChat==true)
        {
            const formData=new FormData()
            console.log("One To One Chat")
            if (selectedFile) {
                console.log("Sendming Media Message")
                formData.append("file",selectedFile)
                formData.append("mediaType",selectedFile.type.split("/")[0])
                formData.append("text",text)
                formData.append("senderName",decodedUser.firstName+" "+decodedUser.lastName)
                let response=await axios.post(`https://api.triphog.net/api/v1/chat/sendmessage/${localStorage.getItem("superAdminId")}/${selectedReceiverId}`,formData,{headers:{"Content-Type":"multipart/form-data"}})
                console.log(response.data)
                if(response.data.success)
                {
                    setMessage("Successfully Message Sent")
                    setIsSnackBarOpen(true)
                    setSeverity("success")
                    setSelectedFile(null)
                    setFilePreview(null) 
                    getChatMessages(selectedReceiverId) 
                getMyChats()
                }
                else{
                    setMessage("Error While Sending Message!")
                    setIsSnackBarOpen(true)
                    setSeverity("error")
                }
                console.log('File to send:', selectedFile);
                
            }
            else if(text.length>0){
                formData.append("senderName",decodedUser.firstName+" "+decodedUser.lastName)
            

                formData.append("text",text)
                let response=await axios.post(`https://api.triphog.net/api/v1/chat/sendmessage/${localStorage.getItem("superAdminId")}/${selectedReceiverId}`,formData,{headers:{"Content-Type":"multipart/form-data"}})
                console.log(response.data)
                if(response.data.success)
                    {
                        setMessage("Successfully Message Sent")
                        setIsSnackBarOpen(true)
                        setSeverity("success")
                        setSelectedFile(null)
                        setFilePreview(null)
                        getChatMessages(selectedReceiverId)
                    getMyChats()
                    }
                    else{
                        setMessage("Error While Sending Message!")
                        setIsSnackBarOpen(true)
                        setSeverity("error")
                    }


            }
            else{
                console.error("Cannot Send Empty Message")
                setMessage("Cannot Send Emtpy Message")
                setSeverity("info")
                setIsSnackBarOpen(true)
            }
        }
        console.log(selectedFile)
      
        setSelectedFile(null);
        setFilePreview(null);
    }
    catch(e){
        toast.error("Error While Sending Message!Please Try Again")
    }
    };
    useEffect(() => {
        console.log("Super Admin Id", localStorage.getItem("superAdminId"));
    
        if (socket) {
            console.log("Super Admin Connected With Socket Server", socket);
    
            socket.on("newMsg", (message) => {
                console.log("SELECTED CHAT RECEIVER ID Is HERE", receiverId);
                console.log("Msg IS COMING From Receiver ID", message.senderId);
    
                if (receiverId) {
                    if (message.senderId === receiverId) {
                        console.log("Matching IDs found");
                        console.log("Msg coming from", message.senderId);
                        console.log("REV ID", receiverId);
                        getChatMessages(message.senderId);
                    }
                    // Always update the chat list for new messages, regardless of match
                    getMyChats();
                } else {
                    getMyChats();
                }
            });
    
            socket.on("disconnect", () => {
                console.log("Super Admin Has Been Disconnected From Socket Server");
            });
        }
    
        // Clean up event listeners when the component unmounts or `socket` changes
        return () => {
            if (socket) {
                socket.off("newMsg");
                socket.off("disconnect");
            }
        };
    }, [socket, receiverId]);
    
    useEffect(()=>{
          console.log("Socket Instance for super admin",socket)
         getMyChats()
         getAllAdmins()
    },[])
  
    return (
        <Box display="flex" width="1300px" height="100vh" bgcolor="white" p={2} gap={2}>
            {/* Left Sidebar */}
            <Paper elevation={3} style={{ width: '25%', borderRadius: '8px', padding: '16px' }}>
    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6" style={{ color: "#30325E", fontFamily: "Cabin" }}>
            Inquiries
        </Typography>
        <IconButton style={{ color: "#30325E", cursor: 'pointer' }} onClick={handleClick}>
            <Add />
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem style={{ color: "#30325E", fontFamily: "Cabin", cursor: 'pointer' }} onClick={() => {
                setIsUsersModelOpen(true);
            }}>
                New Chat
            </MenuItem>
        </Menu>
    </Box>
    <Input
        fullWidth
        placeholder="Search chats"
        margin="normal"
        inputProps={{ style: { fontFamily: "Cabin", borderBottom: "1px solid #30325E" } }}
        style={{ fontFamily: "Cabin" }}
        onChange={(e) => {
            let searchedChats = allChats.filter((chat) => {
                return (chat.with.includes(e.target.value) || chat.lastMessage.text.includes(e.target.value));
            });
            setChats(searchedChats);
        }}
    />
    {isChatsLoading ? (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "50px" }}>
            <CircularProgress style={{ color: "#30325E", height: "18px", width: "18px" }} />
        </div>
    ) : chats.length === 0 ? (
        <Typography style={{ fontFamily: "Cabin", textAlign: "center", marginTop: "20px" }}>
            No Chats Yet!
        </Typography>
    ) : (
        <List style={{ maxHeight: '300px', overflowY: 'auto' }}>
            {chats.map((chat) => (
                <ListItem
                    sx={{
                        backgroundColor: selectedReceiverId === chat.withId && isOneToOneChat === true ? "#30325E" : "white",
                        borderRadius: "5px",
                        color: selectedReceiverId === chat.withId && isOneToOneChat === true ? "white" : "black",
                        cursor: "pointer"
                    }}
                    onClick={async() => {
                        setIsOneToOneChat(true);
                        setSelectedReceiverId(chat.withId);
                        updateChatReceiverId(chat.withId)
                        await getChatMessages(chat.withId);
                        setReceiverName(chat.with);
                    }}
                >
                    <ListItemAvatar>
                        <Avatar sx={{ fontFamily: "Velyra" }}>{chat.with[0]}</Avatar>
                    </ListItemAvatar>
                    <Box style={{ display: "flex", flexDirection: "column" }}>
                        <Typography sx={{ fontFamily: "Cabin" }}>{chat.with}</Typography>
                        <Typography sx={{ fontFamily: "Cabin" }}>
                            {chat.lastMessage.text.length > 0 ? chat.lastMessage.text : chat.lastMessage.mediaUrl.length > 0 && chat.lastMessage.mediaType === "image" ? "Sent An Image" : "Sent A Video"}
                        </Typography>
                    </Box>
                </ListItem>
            ))}
        </List>
    )}
</Paper>

            {/* Main Chat Area */}
            {isOneToOneChat==false   ?     <Paper elevation={3} style={{ flex: 1, borderRadius: '8px', padding: '16px', display: 'flex', flexDirection: 'column', overflow: 'hidden',fontFamily:"Cabin" }}>
            TRIPHOG
            </Paper>: <Paper elevation={3} style={{ flex: 1, borderRadius: '8px', padding: '16px', display: 'flex', flexDirection: 'column', overflow: 'scroll' }}>
                <Box mb={2}>
                    <Typography variant="h6" style={{fontFamily:"Cabin"}}>
                        {receiverName}
                    </Typography>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    height="70vh"
                    style={{overflowY:"scroll"}}
                    p={2}
                    bgcolor="white"
                    borderRadius="8px"
                >
                    {isChatLoading?<div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}><CircularProgress style={{color:"#30325E",height:"18px",width:"18px"}}/></div>:chatMessages.length==0?<Typography style={{fontFamily:"Cabin"}}>No Messages</Typography>: chatMessages.map((message,index) => (
                        <Box key={message._id} variant="outlined" style={{ marginBottom: '10px',height:"350px",width:"50%" ,marginLeft:message.senderId==localStorage.getItem("superAdminId")?"auto":"0px",marginRight:"100px"}}>
                            {message.text.length>0 ? <Card sx={{height:"100px",width:"490px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                                <Typography variant="body1" style={{fontFamily:"Cabin"}}>
                                    {message.text}
                                </Typography>
                                <Typography variant="caption" display="block" sx={{marginLeft:"auto",fontFamily:"Cabin"}}>
                                    {message.addedAt}
                                </Typography>
                            </Card>:message.mediaUrl.length>0 &&message.mediaType=="image"?  <Card  sx={{height:"150px",width:"490px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                                <img style={{height:"85%",width:"98%",borderRadius:"5px"}} src={message.mediaUrl} />
                                <Typography variant="caption" display="block" sx={{marginLeft:"auto",fontFamily:"Cabin"}}>
                                    {message.addedAt}
                                </Typography>
                            </Card>:  <Card  sx={{height:"150px",width:"490px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                                <video style={{height:"85%",width:"98%",borderRadius:"5px"}} src={message.mediaUrl} controls={true}></video>                          
                                <Typography variant="caption" display="block" sx={{marginLeft:"auto",fontFamily:"Cabin"}}>
                                    {message.addedAt}
                                </Typography>
                            </Card>}
              {index === chatMessages.length - 1 && <div ref={messagesEndRef} />}
                          
                        </Box>
                    ))}

                    {filePreview && (
                        <Box mt={2} mb={2} position="relative">
                            {selectedFile.type.startsWith('image/') && (
                                <img src={filePreview} alt="preview" style={{ maxWidth: '100%', borderRadius: '8px' }} />
                            )}
                            {selectedFile.type.startsWith('video/') && (
                                <video controls style={{ maxWidth: '100%', borderRadius: '8px' }}>
                                    <source src={filePreview} type={selectedFile.type} />
                                </video>
                            )}
                            <IconButton
                                style={{ position: 'absolute', top: 0, right: 0 }}
                                onClick={() => {
                                    setFilePreview(null);
                                    setSelectedFile(null);
                                }}
                            >
                                <Close />
                            </IconButton>
                        </Box>
                    )}
                </Box>
                <Box display="flex" alignItems="center" mt={2} borderTop="1px solid lightgrey">
                    <Input
                        fullWidth
                        placeholder="Write message"
                        
                        onChange={(e)=>{
                            setText(e.target.value)
                        }}
                        inputProps={{style:{fontFamily:"Cabin"}}}
                        style={{ borderBottom: '1px solid #30325E' ,fontFamily:"Cabin"}}
                    />
                    <input
                        type="file"
                        accept="image/*,video/*"
                        style={{ display: 'none' }}
                        id="file-input"
                        onChange={handleFileChange}
                    />
                    <label htmlFor="file-input">
                        <IconButton style={{color:"#30325E"}} component="span">
                            <AttachFile />
                        </IconButton>
                    </label>
                    <IconButton style={{color:"#30325E"}} onClick={handleSendMessage}>
                        <Send />
                    </IconButton>
                </Box>
            </Paper>}


            {/* New Group Modal */}
          
        <Modal
    open={isUsersModelOpen}
    onClose={handleCloseUsersModal}
    aria-labelledby="new-group-modal"
    aria-describedby="new-group-modal-description"
>
    <Box
        sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            maxHeight: '80vh', // Ensures the modal doesn't take up more than 80% of the screen height
            overflowY: 'auto', // Makes the modal scrollable if content overflows
            bgcolor: 'background.paper',
            borderRadius: '8px',
            boxShadow: 24,
            p: 4
        }}
    >
        

        <Typography variant="subtitle1" mt={2} mb={1} style={{fontFamily:"Cabin"}} >ADMINS</Typography>
       {admins.length === 0 ? (
            <Typography style={{fontFamily:"Cabin"}}>No Admins Available!</Typography>
        ) : (
            admins.map((admin) => (
                <Card 
                onClick={()=>{
                    setIsOneToOneChat(true);
                  
                     setSelectedReceiverId(admin._id);
                      getChatMessages(admin._id);
                      setReceiverName(admin.firstName+" "+admin.lastName)
                      setIsUsersModelOpen(false)
                      setAnchorEl(null)
                    
                }}
                    key={admin.id}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: 2, // Adds space between cards
                        p: 1,
                        boxShadow: 3,
                        borderRadius: '8px'
                    }}
                >
                    <Avatar sx={{ bgcolor: '#30325E', mr: 2,fontFamily:"Cabin" }}>
                        {admin.firstName[0]}
                    </Avatar>
                    <Typography variant="body1" sx={{ flexGrow: 1,fontFamily:"Cabin" }}>
                        {admin.firstName + " " + admin.lastName}
                    </Typography>
                </Card>
            ))
        )}

      
    </Box>
</Modal>

        <Snackbar
        open={isSnackBarOpen}
        autoHideDuration={6000}
        onClose={handleSnackBarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackBarClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
);
};

export default Chat;

