import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSocket } from "../../SocketContext/SocketContext";
import {jwtDecode} from "jwt-decode";

const Rides = () => {
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const socket = useSocket();
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  let decodedUser;

  if (token) {
    decodedUser = jwtDecode(token); // Decode token if available
  }

  const getMyChats = async () => {
    if (!token) return; // Check for token here instead
    try {
      const response = await axios.get(
        "https://api.triphog.net/api/v1/chat/getmychats",
        {
          headers: { Authorization: token },
        }
      );
      const sortedChats = response.data.chats.sort((a, b) => {
        const dateA = new Date(a.lastMessage.addedON);
        const dateB = new Date(b.lastMessage.addedON);
        return dateB - dateA; // Sort descending (latest first)
      });
      setChats(sortedChats.slice(0, 3)); // Show only the first 3 chats
      setLoading(false); // Stop loading after fetching data
    } catch (error) {
      console.error("Error fetching chats:", error);
      setLoading(false); // Stop loading in case of an error
    }
  };

  useEffect(() => {
    if (socket && token) { // Add token check here
      socket.on("new-notification", () => {
        getMyChats();
      });
    }
    getMyChats(); // Fetch chats initially
  }, [socket, token]);

  const truncateMessage = (message, length) => {
    return message.length > length ? message.slice(0, length) + "..." : message;
  };

  // If no token, display a login prompt
  if (!token) {
    return (
      <div className="bg-white rounded-[20px] p-[20px] mt-[20px] shadow-lg rides-section relative">
        <p className="text-gray-500 text-center mt-5">Please log in to view chats.</p>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-[20px] p-[20px] mt-[20px] shadow-lg rides-section relative">
      {/* Header Section */}
      <div className="flex items-center justify-between text-lap_c font-[600] p-4 bg-gray-100 rounded-lg">
        <h2 className="text-xl font-semibold">Messages</h2>
      </div>
      <hr className="my-[10px]" />

      {/* Loading Spinner */}
      {loading ? (
        <div className="flex justify-center items-center h-[200px]">
          <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-b-4 border-deepBlue"></div>
        </div>
      ) : (
        <>
          {/* Chat List */}
          {chats.length > 0 ? (
            chats.map((chat) => (
              <div
                onClick={() => navigate("/admin/chat")}
                key={chat._id}
                className={`relative flex cursor-pointer my-[15px] items-center gap-5 justify-between border rounded-lg p-[15px] shadow-sm hover:shadow-md transition-shadow duration-300 ${
                  chat.lastMessage.isRead ||
                  (chat.lastMessage.senderId ===
                    (decodedUser.role === "Admin"
                      ? decodedUser.id
                      : decodedUser.createdBy))
                    ? "bg-white border-gray-300"
                    : "bg-white border-red-600"
                }`}
              >
                <div className="h-[50px] w-[50px] flex items-center justify-center rounded-full bg-gray-300 text-xl font-semibold text-white shadow-md">
                  {chat.with[0].toUpperCase()}
                </div>

                <div className="flex-grow">
                  <h2 className="font-[600] text-lg text-black">
                    {chat.with}
                  </h2>
                  <p className="text-sm text-black">
                    {chat.lastMessage.text.length > 0
                      ? truncateMessage(chat.lastMessage.text, 20)
                      : chat.lastMessage.mediaUrl
                      ? chat.lastMessage.mediaType === "image"
                        ? chat.lastMessage.senderId ===
                          (decodedUser.role === "Admin"
                            ? decodedUser.id
                            : decodedUser.createdBy)
                          ? "You sent an image"
                          : "Sent an image"
                        : chat.lastMessage.mediaType === "video"
                        ? chat.lastMessage.senderId ===
                          (decodedUser.role === "Admin"
                            ? decodedUser.id
                            : decodedUser.createdBy)
                          ? "You sent a video"
                          : "Sent a video"
                        : ""
                      : "No recent messages"}
                  </p>
                </div>

                <div className="flex items-center gap-2">
                  {!chat.lastMessage.isRead &&
                    chat.lastMessage.senderId !==
                      (decodedUser.role === "Admin"
                        ? decodedUser.id
                        : decodedUser.createdBy) && (
                      <div className="h-[18px] w-[18px] bg-red-600 rounded-full"></div>
                    )}

                  <div className="text-sm text-black">
                    {new Date(chat.lastMessage.addedON).toLocaleTimeString(
                      [],
                      {
                        hour: "2-digit",
                        minute: "2-digit",
                      }
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-500 text-center mt-5">
              No chats available.
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default Rides;
