import React, { useEffect, useState } from "react";
// import { useUpdateAdminContext } from "../../../../Providers/SuperAdmin/UpdateAdminProvider";
import RideprofileHeader from "./RideprofileHeader";
import SingleDriverSideBar from "./SingleRideSideBar";

import Modal from "../../../Modal";
import { GoogleMap, LoadScript,useLoadScript, Marker,DirectionsService,DirectionsRenderer,InfoWindow } from "@react-google-maps/api";
import Map from "./Map";
import { error } from "ajv/dist/vocabularies/applicator/dependencies";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast,ToastContainer } from "react-toastify";
import io from 'socket.io-client'
import { CircularProgress ,Box} from "@mui/material";
import { jwtDecode } from "jwt-decode";
import { useSocket } from "../../../SocketContext/SocketContext";
const libraries = ['places'];


const SingleRide = () => {
   const socket=useSocket()
  
  // const { isLoaded, loadError } = useLoadScript({
  //   googleMapsApiKey: 'AIzaSyCRDiod9UUH0SHTiIDg2k3fW_FtO0EekSg',
  //   libraries,
  // });
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCRDiod9UUH0SHTiIDg2k3fW_FtO0EekSg',
    libraries,
  });
  let navigate=useNavigate()
  const[loading,setLoading]=useState(true)
  const[isDriverMissing,setIsDriverMissing]=useState(false)
  const{id}=useParams()
  const[driverId,setDriverId]=useState("")
  const[trip,setTrip]=useState({})
  const[latitude,setLatitude]=useState(0)
  const[longitude,setLongitude]=useState(0)
  const[directions,setDirections]=useState(null)
  const[duration,setDuration]=useState("")
  const[distance,setDistance]=useState("")
  const[infoWindowPosition,setInfoWindowPosition]=useState(null)
  const convertMilesToKilometers = (distanceText) => {
    const regex = /(\d+(\.\d+)?)\s*mi/i;
    const match = distanceText.match(regex);
    if (match) {
      const miles = parseFloat(match[1]);
      const kilometers = miles * 1.60934;
      return `${kilometers.toFixed(2)} km`;
    }
    return distanceText;
  }; 
 let getTripById=async()=>{
  try{
  const token=localStorage.getItem("token")
  let response=await axios.get(`https://api.triphog.net/api/v1/trip/gettripbyId/${id}`,{headers:{'Authorization':token}})
  console.log(response.data)
  if(response.data.trip.driverRef.length>0)
  {
  let driverResponse=await axios.get(`https://api.triphog.net/api/v1/driver/getdriver/${response.data.trip.driverRef}`,{headers:{'Authorization':token}})

    console.log(driverResponse.data)
  setDriverId(response.data.trip.driverRef)
  // setLongitude(driverResponse.data.driver.longitude)
  // setLatitude(driverResponse.data.driver.latitude)

  }
  

  setTrip(response.data.trip)
  setLoading(false)
  if(isLoaded)
  {

  const directionsService = new window.google.maps.DirectionsService();
      await directionsService.route(
        {
          origin: response.data.trip.pickUpAddress,
          destination: response.data.trip.dropOffAddress,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            console.log(status)
            console.log(result)
            const route = result.routes[0].legs[0];
            console.log(route.distance.text)
            console.log(route.duration.text)
            let distanceInKM=convertMilesToKilometers(route.distance.text)
            console.log(distanceInKM)
            const midPoint = route.steps[Math.floor(route.steps.length / 2)].end_location;
            console.log(midPoint)
            setDuration(route.duration.text)
            setDistance(distanceInKM)
            setInfoWindowPosition(midPoint);
            setDirections(result);
          } else {
            console.error(`Error fetching directions ${result}`);
          }
        }
      ); 
    }
    else{
      console.error("There Was An Error While Loading Google Maps")
    }
  }
  catch(e){
    setLoading(false)
toast.error("Something Went Wrong While Getting Trip!")
  }
      
 } 
useEffect(()=>{
  if(socket)
    {
     socket.on("update-location",(location)=>{
       console.log("Location Has Been Updated",location)
       if(location.tripId==id)
       {
         console.log("Changing Location Of Driver ON Map",location)
         setLatitude(location.lat)
         setLongitude(location.lng)
       }
      
     })
    }
},[socket])
  useEffect(()=>{
   // const socket=io("https://api.triphog.net",{query:{userId:decodedUser.role=="Admin"?localStorage.getItem("adminId"):decodedUser.createdBy}})
  
  
    if (isLoaded) {
      getTripById();
    }
  
  // let DirectionsService=  new window.google.maps.DirectionsService()
  // DirectionsService.route({origin:{lat:33.6844,lng:73.0479},destination:{lat:33.6844,lng:73.0531},travelMode:window.google.maps.TravelMode.DRIVING},(error,results)=>{
  //   console.log(results)
  // })
  },[isLoaded,socket])
  // if (loadError) return <div>Error loading maps</div>;
  // if (!isLoaded) return <div>Loading Maps</div>;
  const decodedUser=jwtDecode(localStorage.getItem("token"))

  let checkPermission=(permission)=>{
    console.log("Decoded User Permissions",decodedUser)
    if(decodedUser.role=="Admin")
    {
      return false
    }
    else{
      return (decodedUser.accessibilities.includes(permission))
    }
    

  }
  const handleGoBack = () => {
    navigate(-1); // Navigate to the previous page
  };
  if(decodedUser.role=="User" &&(!checkPermission("Access To View Trip")))
  {
    return (
      <div className="flex items-center justify-center h-screen bg-deepBlue mt-[20px] rounded-md">
        <div className="bg-white p-8 rounded-lg shadow-lg text-center w-full max-w-md">
          <h1 className="text-3xl font-bold text-deepBlue mb-4">Unauthorized</h1>
          <p className="text-gray-700 mb-6">
            You do not have access to this page. Please contact the administrator or go back.
          </p>
          <ToastContainer/>
          <button
            onClick={handleGoBack}
            className="bg-deepBlue text-white px-4 py-2 rounded-lg hover:bg-deepBlue-dark transition-colors"
          >
            Go Back
          </button>
        </div>
      </div>
    );
  }
  else{
if(loading){
  return(
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <CircularProgress size={60} thickness={5} color="inherit" />
    </div>
  )
}
else{
  return (
    <>
      <div className="bg-white rounded-[20px] p-[30px] mt-[20px] laptop:mr-[30px]">
      <button style={{display:"flex",flexDirection:"row"}} onClick={()=>{
         navigate(-1) 
        }}>
          <img style={{height:"26px",width:"23px"}} src={require('./left-arrow.png')}/>
          <h5 style={{marginRight:"8px",margin:"1px"}}>BACK</h5>
          
          </button>
      
        <div>
          <form
            className="text-darkgray laptop:text-lap_b"
            // onSubmit={handleSubmit}
          >
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label className="">Trip Ref</label>
                <br />
                <input
                value={trip._id}
                  type="text"
                  // placeholder={firstName}
                  // onChange={(event) => {
                  //   setFirstName(event.target.value);
                  // }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px]">
                <label>Pickup Date</label>
                <br />
                <input
                value={new Date(trip.pickUpDate).getDate()+"/"+new Date(trip.pickUpDate).getMonth()+"/"+new Date(trip.pickUpDate).getFullYear()}
                  
                  // placeholder={lastName}
                  // onChange={(event) => {
                  //   setLastName(event.target.value);
                  // }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            </div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label className="">Appointment Time</label>
                <br />
                <input
                value={trip.appointmentTime}
                  type="text"
                  // placeholder={email}
                  // onChange={(event) => {
                  //   setEmail(event.target.value);
                  // }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Pick Up Time</label>
                <br />
                <input
                value={trip.pickUpTime}
                  type="text"
                  disabled
                  // placeholder={phoneNumber}
                  // onChange={(event) => {
                  //   setPhoneNumber(event.target.value);
                  // }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            
</div>

            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label className="">Patient Ref</label>
                <br />
                <input
                  type="text"
                  value={trip.patientRef}
                  // placeholder={phoneNumber}
                  // onChange={(event) => {
                  //   setPhoneNumber(event.target.value);
                  // }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />

              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Patient Name (Full)</label>
                <br />
                <input
                value={trip.patientName}
                  type="text"
                  // placeholder={phoneNumber}
                  // onChange={(event) => {
                  //   setPhoneNumber(event.target.value);
                  // }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            </div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label className="">Driver Ref</label>
                <br />
                <input
                value={trip.driverRef}
                  type="text"
                  // placeholder={phoneNumber}
                  // onChange={(event) => {
                  //   setPhoneNumber(event.target.value);
                  // }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Driver Name</label>
                <br />
                <input
                value={trip.driverName}
                  type="text"
                  // placeholder={phoneNumber}
                  // onChange={(event) => {
                  //   setPhoneNumber(event.target.value);
                  // }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            </div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label className="">Patient Phone number</label>
                <br />
                <input
                  type="text"
                  value={trip.patientPhoneNumber}
                  // placeholder={email}
                  // onChange={(event) => {
                  //   setEmail(event.target.value);
                  // }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Patient Type</label>
                <br />
                <input
                  type="text"
                  value={trip.patientType}
                  // placeholder={phoneNumber}
                  // onChange={(event) => {
                  //   setPhoneNumber(event.target.value);
                  // }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            </div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label className="">Mileage</label>
                <br />
                <input
                value={Number(trip.mileage)>0?trip.mileage:0}
                  type="text"
                  // placeholder={email}
                  // onChange={(event) => {
                  //   setEmail(event.target.value);
                  // }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Leg ID</label>
                <br />
                <input
                value={trip.legId}
                  type="text"
                  // placeholder={phoneNumber}
                  // onChange={(event) => {
                  //   setPhoneNumber(event.target.value);
                  // }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            
</div>
<div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label className="">Pick Up Address</label>
                <br />
                <input
                value={trip.pickUpAddress}
                  type="text"
                  // placeholder={email}
                  // onChange={(event) => {
                  //   setEmail(event.target.value);
                  // }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Dropoff Address</label>
                <br />
                <input
                value={trip.dropOffAddress}
                  type="text"
                  // placeholder={phoneNumber}
                  // onChange={(event) => {
                  //   setPhoneNumber(event.target.value);
                  // }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            
</div>


          </form>
        </div>
        
        
        {isLoaded&&(  <GoogleMap zoom={10} mapContainerStyle={{height:"500px",width:"100%"}}  center={{
  lat: latitude,
  lng: longitude
}}>
 {directions && (
          <>
            <DirectionsRenderer directions={directions} />
            {infoWindowPosition && (
              <InfoWindow position={infoWindowPosition}>
                <div>
                  <p style={{color:"skyblue",fontWeight:"bold"}}>Distance: {distance}</p>
                  <p style={{color:"skyblue",fontWeight:"bold"}}>Duration: {duration}</p>
                </div>
              </InfoWindow>
            )}
          </>
        )}
<Marker position={{lat:latitude,lng:longitude}}/>


          </GoogleMap>)}
        
        
      </div>

      <div className="bg-white laptop:mt-[-80px]">
        <SingleDriverSideBar driverId={driverId} />
      </div>
      <>
        {/* {status && (
          <Modal status={status} setStatus={setStatus} message={modalMessage} />
        )} */}
      </>
    </>
  );
}}
};


export default SingleRide;
