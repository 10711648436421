import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoNotificationsOutline } from "react-icons/io5";
import { TbRectangleFilled } from "react-icons/tb";
const UsersSideBar = (props) => {
  const[users,setUsers]=useState([])
  const[activeUsers,setActiveUsers]=useState([])
  const[notActiveUsers,setNotActiveUsers]=useState([])
  const[onHoldUsers,setOnHoldUsers]=useState([])
  let getAllUsers=async()=>{

    const token=localStorage.getItem("token")
    let response=await axios.get("https://api.triphog.net/api/v1/user/getallusers",{headers:{'Authorization':token}})
    console.log(response.data)
    setUsers(response.data.users)
    setActiveUsers(response.data.users.filter((user)=>{
      return(user.status=="Active")
    }))
    setNotActiveUsers(response.data.users.filter((user)=>{
      return(user.status=="Not Active")
    }))
    setOnHoldUsers(response.data.users.filter((user)=>{
      return(user.status=="On Hold")
    }))
  }
  useEffect(()=>{
    getAllUsers()

  },[props])
  return (
    <div className="laptop:grid hidden laptop:sticky laptop:top-0 laptop:left-0 laptop:right-0 p-[25px] bg-white min-h-screen overflow-y-auto">
      {/* Added overflow-y-auto to enable scrolling */}
      <div className="w-[100%] ">
        <div className="flex float-right text-lap_e cursor-pointer">
          <IoNotificationsOutline />
        </div>
        <h2 className="text-lap_c mt-[60px] font-[600]">Users</h2>
        <h2 className="text-lap_d font-[700] text-deepBlue">{users.length}</h2>
        <div className="grid grid-cols-2 items-center my-[20px] gap-[10%]">
          <div>
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#409261] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{activeUsers.length}</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">Active</span>
            </div>
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#D98634] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{onHoldUsers.length}</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">On Hold</span>
            </div>
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#E42346] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{notActiveUsers.length}</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">Inactive</span>
            </div>
          </div>
          <div>Chart</div>
        </div>
       
        
      </div>
    </div>
  );
};

export default UsersSideBar;
