import { CircularProgress,Box } from "@mui/material";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast,ToastContainer } from "react-toastify";

const FrequentVisitPages = () => {
  const [frequentlyVisitedPages, setFrequentlyVisitedPages] = useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  console.log("Token Found", token);
  const[decodedUser,setDecodedUser]=useState({})
  let _decodedUser={}
  const[loading,setLoading]=useState(false)
  const[fullName,setFullName]=useState("")
  const allPages=[
    {
    title:"Dashboard",
    path:"/admin"
  },
  {
    title:"View Trips",
    path:"/admin/trips"
  },
  {
    title:"View Patients",
    path:"/admin/patient"
  },
  {
    title:"View Drivers",
    path:"/admin/drivers"
  },
  {
    title:"Schedule Meeting",
    path:"/admin/meeting"
  },
  {
    title:"Chats",
    path:"/admin/chat"
  },
  {
    title:"Billing History",
    path:"/admin/plan"
  },
  {
    title:"Trip Reviews",
    path:"/admin/tripreviews"
  },
  {
    title:"Trip Logs",
    path:"/admin/triplogs"
  },
  {
    title:"Documents",
    path:"/admin/documents"
  },
  {
    title:"Payrol",
    path:"/admin/payrol"
  },
  {
    title:"View Users",
    path:"/admin/users"
  },
  {
    title:"Settings",
    path:"/admin/settings"
  },
]
const[isSaving,setIsSaving]=useState(false)
const saveFrequentlyVisitedPages=async()=>{
  setIsSaving(true)
  try{
    let response=await axios.put("https://api.triphog.net/api/v1/admin/update-frequently-visited-pages",{frequentlyVisitedPages},{headers:{"Authorization":localStorage.getItem("token")}})
    if(response.data.success)
    {
      toast.success("Successfully Saved!")
    }
    else{
      toast.error("Error While Saving!")
    }

  }
  catch(e){
    toast.error("Error While Saving!")

  }
  finally{
    setIsSaving(false)

  }
}

  

  useEffect(() => {
    setLoading(true)
    const fetchAdminData = async () => {
      try {
        if (!token) {
          navigate("/admin/login");
        } else {
          try {
            _decodedUser = jwtDecode(token);
            setDecodedUser(_decodedUser)
          } catch (error) {
            console.error("Invalid token:", error);
            navigate("/admin/login");
            return;
          }

          if (_decodedUser?.role === "Admin") {
            try {
              const response = await axios.get(
                `https://api.triphog.net/api/v1/superadmin/admin/getById/${token}`,{
                  headers:{
                    "Authorization":token
                  }
                }
              );
              console.log("Admin Data", response.data);
              setFullName(response.data.data.firstName+" "+response.data.data.lastName)
              setFrequentlyVisitedPages(response.data.data.frequentlyVisitedPages);
            } catch (error) {
              console.error("Error fetching admin data:", error);
            }
          } else {
            console.warn("User is not an admin");
            toast.warn("Staff Cannot Access Settings!Please Login As Admin")
            setTimeout(()=>{
              navigate("/admin/login");

            },[2000])
          }
        }
      } catch (error) {
        console.error("Unexpected error:", error);
        navigate("/admin/login");
      }
      finally{
        setLoading(false)
      }
    };

    fetchAdminData();
  }, [token, navigate]);
  if(loading)
  {
    return(
      <Box sx={{display:"flex",flexDiirection:"column",alignItems:"center",justifyContent:"center"}}>
        <CircularProgress sx={{color:"#30325E",width:"30px"}}/>
      </Box>
    )
  }
  else{

  return (
    <div className="h-screen">
      <div className="bg-white rounded-[20px] p-[10%] mt-[20px] mr-[30px]">
        <h1 className="text-center font-[700] text-[25px] mb-[30px]">
          {fullName}'s Frequently Visited Pages
        </h1>
        <div className="w-[55%] grid mx-auto items-center my-3">
          <label className="text-[#606060]">
            Select the top page you frequently visit.
          </label>
          <div className="laptop:mb-4 mb-8 relative laptop:text-[15px] text-[40px] my-[5px]">
            <select className="mt-[8px] border-[0.6px] cursor-pointer rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white" defaultValue={frequentlyVisitedPages[0]?.title} onChange={(e)=>{
             let path= allPages.filter((page)=>{
                return(page.title==e.target.value)
              })[0].path
              frequentlyVisitedPages[0]={title:e.target.value,path}
              console.log("Frequentyly Visited Pages Here",frequentlyVisitedPages)
              setFrequentlyVisitedPages(frequentlyVisitedPages)
            }}>
            {
              allPages.map((page)=>{
                if(page.title==frequentlyVisitedPages[1]?.title||page.title==frequentlyVisitedPages[2]?.title)
                {
                  return ;
                }
                else {
                  return (<option value={page.title}>{page.title}</option>)
                }
              })
            }
            </select>
          </div>
        </div>
        <div className="w-[55%] grid mx-auto items-center my-3">
          <label className="text-[#606060]">
            Select the second page you frequently visit.
          </label>
          <div className="laptop:mb-4 mb-8 relative laptop:text-[15px] text-[40px] my-[5px]">
            <select onChange={(e)=>{
              let path= allPages.filter((page)=>{
                return(page.title==e.target.value)
              })[0].path
              frequentlyVisitedPages[1]={title:e.target.value,path}
              console.log("Frequentyly Visited Pages Here",frequentlyVisitedPages)
              setFrequentlyVisitedPages(frequentlyVisitedPages)

            }} className="mt-[8px] border-[0.6px] cursor-pointer rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white" defaultValue={frequentlyVisitedPages[1]?.title}>
              {
                allPages.map((page)=>{
                  if(page.title==frequentlyVisitedPages[0]?.title||page.title==frequentlyVisitedPages[2]?.title)
                  {
                    return ;
                  }
                  else {
                    return (<option value={page.title}>{page.title}</option>)
                  }
                })

              }
            </select>
          </div>
        </div>
        <div className="w-[55%] grid mx-auto items-center my-3">
          <label className="text-[#606060]">
            Select the third page you frequently visit.
          </label>
          <div className="laptop:mb-4 mb-8 relative laptop:text-[15px] text-[40px] my-[5px]">
            <select onChange={(e)=>{
               let path= allPages.filter((page)=>{
                return(page.title==e.target.value)
              })[0].path
              frequentlyVisitedPages[2]={title:e.target.value,path}
              console.log("Frequentyly Visited Pages Here",frequentlyVisitedPages)
              setFrequentlyVisitedPages(frequentlyVisitedPages)

            }} className="mt-[8px] border-[0.6px] cursor-pointer rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white" defaultValue={frequentlyVisitedPages[2]?.title}>
              {
                 allPages.map((page)=>{
                  if(page.title==frequentlyVisitedPages[1]?.title||page.title==frequentlyVisitedPages[0]?.title)
                  {
                    return ;
                  }
                  else {
                    return (<option value={page.title}>{page.title}</option>)
                  }
                })
              }
            </select>
          </div>
        </div>
        <div className="text-center">
          <Link>
          <button onClick={(e)=>{
            e.preventDefault()
            saveFrequentlyVisitedPages()
          
          }}
  className="div items-center justify-center px-[50px] bg-deepBlue text-white laptop:py-2 py-4 rounded-lg transition-colors duration-300 laptop:text-[17px] text-[40px]"
>
  {isSaving ? (
    <CircularProgress size={24} style={{ color: 'white' }} />
  ) : (
    "Save"
  )}
</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
};

export default FrequentVisitPages;
