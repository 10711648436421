import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="bg-gray-100 py-12 px-6 lg:px-16">
      <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-3xl font-semibold text-gray-800 mb-6">Privacy Policy</h1>
        
        <p className="text-gray-700 mb-4">
          Welcome to TripHog! We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, and protect your information when you use our services.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">1. Information We Collect</h2>
        <p className="text-gray-700 mb-4">
          We may collect personal information such as your name, email address, phone number, and location data when you use our services. This information is essential for providing you with a seamless experience on our platform.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">2. How We Use Your Information</h2>
        <p className="text-gray-700 mb-4">
          The information we collect is used to enhance your experience on TripHog. We may use your data to improve our services, customize your experience, and communicate with you about updates or special offers.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">3. Data Sharing and Security</h2>
        <p className="text-gray-700 mb-4">
          We do not sell or share your personal information with third parties for their marketing purposes. We implement robust security measures to protect your data from unauthorized access, disclosure, or misuse.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">4. Your Rights and Choices</h2>
        <p className="text-gray-700 mb-4">
          You have the right to access, update, or delete your personal information at any time. If you have any concerns about your privacy or data security, please contact our support team for assistance.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">5. Changes to This Policy</h2>
        <p className="text-gray-700 mb-4">
          We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to review this policy periodically to stay informed about how we are protecting your information.
        </p>

        <p className="text-gray-700 mt-8">
          By using TripHog, you agree to this Privacy Policy and our terms of service. If you have any questions or concerns, please feel free to contact us.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
