import { Box, CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

const Wellcome = () => {
  const[superAdmin,setSuperAdmin]=useState({})
  let getSuperAdmin=async()=>{
    let response=await axios.get("https://api.triphog.net/api/v1/superadmin/getsuperadmin",{headers:{"Authorization":localStorage.getItem("superadmintoken")}})
    console.log("Super Admin",response.data)
    setSuperAdmin(response.data.superAdmin)
  }
  useEffect(()=>{
    getSuperAdmin()
  },[])
  if(Object.keys(superAdmin).length==0)
  {
    return(<Box className="flex flex-col justify-center items-center">
      <CircularProgress style={{color:"#30325E",height:"18px",width:"18px"}} />
    </Box>)
  }
  else{
  return (
    <div className="laptop:grid laptop:grid-cols-[8%_auto] laptop:items-center hidden bg-[#F4F7FF] laptop:px-[30px] laptop:pt-[8px]">
      <div className="bg-[#C8F7DC] h-[60px] w-[60px] rounded-full flex flex-col justify-center items-center">
      <h1>{superAdmin.firstName[0]}</h1>
      </div>
      <div>
        <h1 className="text-[16px] text-[#262626]">
          <b>Welcome Back</b>, {superAdmin.firstName+" "+superAdmin.lastName}👋{" "}
        </h1>
        <h3 className="text-[10px] text-[#26262657] font-[400]">Super Admin</h3>
      </div>
    </div>
  );
}
};

export default Wellcome;
