import axios from "axios";
import React, { useState, useEffect } from "react";
import { CiLock } from "react-icons/ci";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const CreateUserPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams();
  const [_token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (token) {
      setToken(token);
    }
  }, [token, setToken]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Passwords Do Not Match!", { position: "top-center" });
    } else {
      setLoading(true);
      try {
        let response = await axios.post(
          `https://api.triphog.net/api/v1/user/createpassword/${_token}`,
          { password, confirmPassword }
        );
        if (response.data.success) {
          toast.success("Password Has Been Created!", { position: "top-center" });
          setLoading(false);
          // navigate to the login page or some other page
          setTimeout(()=>{navigate("/admin/login")},1000)
        } else {
          toast.error("There Was An Error While Creating Password!", { position: "top-center" });
          setLoading(false);
        }
      } catch (error) {
        toast.error("Something went wrong!", { position: "top-center" });
        setLoading(false);
      }
    }
  };

  return (
    <div className="flex flex-col lg:flex-row h-screen">
      {/* Left side (logo and image) */}
      <div className="lg:flex-1 flex items-center justify-center bg-gray-100">
        <img
          src="/uploads/login.jpg"
          alt="login"
          className="w-full h-auto object-cover sm:h-[300px] md:h-[400px] lg:h-full"
        />
      </div>
      {/* Right side (form) */}
      <div className="flex flex-1 flex-col justify-center items-center lg:mt-0 mt-6">
        <div className="bg-white p-8 rounded-lg w-full max-w-[500px]">
          <h2 className="text-xl lg:text-2xl font-bold mb-6 text-center">Password Creation</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4 relative text-lg">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 pl-12"
              />
              <span className="absolute inset-y-0 left-0 flex items-center text-xl text-gray-400 pl-4">
                <CiLock />
              </span>
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-0 pr-4 flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
              >
                {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
              </button>
            </div>

            <div className="mb-4 relative text-lg">
              <input
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 pl-12"
              />
              <span className="absolute inset-y-0 left-0 flex items-center text-xl text-gray-400 pl-4">
                <CiLock />
              </span>
              <button
                type="button"
                onClick={toggleConfirmPasswordVisibility}
                className="absolute inset-y-0 right-0 pr-4 flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
              >
                {showConfirmPassword ? <FaRegEyeSlash /> : <FaRegEye />}
              </button>
            </div>

            <button
              type="submit"
              disabled={loading}
              className="w-full bg-deepBlue text-white py-2 rounded-md hover:bg-deepBlue transition-colors duration-300 text-lg"
            >
              {loading ? "Creating..." : "Create Password"}
            </button>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CreateUserPassword;
