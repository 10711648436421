import React, { useEffect, useState } from "react";
import { createContext, useContext } from "react";
import axios from "axios";
import { Form } from "react-router-dom";

const AddNewAdminContext = createContext();

const AddNewAdminProvider = ({ children }) => {
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [photo, setPhoto] = useState(null);
  const[companyName,setCompanyName]=useState("")
  const[companyCode,setCompanyCode]=useState("")

  const [features, setFeatures] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [modalMessage, setModalMessage] = useState("");

  useEffect(() => {
    if (submit) {
      const fetchData = async () => {
        try {
          setLoading(true);
          setStatus("");
          setModalMessage("");
          if (
            !firstName ||
            !lastName ||
            !email ||
            !phoneNumber||
            !photo||
            !companyName||
            !companyCode
          ) {
            setModalMessage("Please fill in all required fields.");
            setStatus("error");
            setLoading(false);
            setSubmit(false);
            return;
          }
const formData=new FormData()
formData.append("firstName",firstName)
formData.append("lastName",lastName)
formData.append("email",email)
formData.append("phoneNumber",phoneNumber)
formData.append("companyCode",companyCode)
formData.append("companyName",companyName)
formData.append("features",features)
formData.append("profilePhoto",photo)
          const { data } = await axios.post(
            `https://api.triphog.net/api/v1/superadmin/admin`,
            formData,{headers:{"Content-Type":"mutipart/form-data"}}
          );
          console.log("Admin Adding Response",data)

          if (data.status === "success") {
            setStatus("success");
            setModalMessage("Congratulations, admin added successfully");
          } else {
            setStatus("error");
            setModalMessage("Something went wrong. Please try again later.");
          }
        } catch (err) {
          console.error("Error fetching data:", err);
          setStatus("error");
          setModalMessage("Something went wrong. Please try again later.");
        } finally {
          setLoading(false);
          setSubmit(false);
        }
      };
      fetchData();
    }
  }, [submit]);

  return (
    <AddNewAdminContext.Provider
      value={{
        setFirstName,
        setLastName,
        setEmail,
        setPhoneNumber,
        setCompanyName,
        setCompanyCode,
        setSubmit,
        photo,
        setPhoto,
        setFeatures,
        features,
        loading,
        //
        status,
        setStatus,
        message: modalMessage,
      }}
    >
      {children}
    </AddNewAdminContext.Provider>
  );
};

export const useAddNewAdminContext = () => {
  return useContext(AddNewAdminContext);
};

export default AddNewAdminProvider;
