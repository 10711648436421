import { IoArrowBackOutline } from "react-icons/io5";
import { MdLinkedCamera } from "react-icons/md";
import { IoNotificationsOutline } from "react-icons/io5";
import { FaPencil } from "react-icons/fa6";
import { RiDeleteBin5Line } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast,ToastContainer } from "react-toastify";

// import Modal from "../../../Modal";
import AddNewUserSideBar from "./AddNewUserSideBar";
import { jwtDecode } from "jwt-decode";
import { CircularProgress } from "@mui/material";

const AddNewUser = () => {
   // const { setFeatures, features } = useAddNewAdminContext();
   const[firstName,setFirstName]=useState("")
   const[lastName,setLastName]=useState("")
   const[EMailAddress,setEMailAddress]=useState("")
   const[phoneNumber,setPhoneNumber]=useState("")
   const [dummyfeatures, setDummyFeatures] = useState(["Access to financial data","Access to system settings","Access to subscriber information","Access to feedback management"]);
   const [newFeature, setNewFeature] = useState("");
   const[companyName,setCompanyName]=useState("")
  const decodedUser=jwtDecode(localStorage.getItem("token"))
   const [selectedPermissions, setSelectedPermissions] = useState([]);
  const[preview,setPreview]=useState("")
  const[profilePhoto,setProfilePhoto]=useState(null)
   const permissions = [
     'Access To Add Driver',
     'Access To Delete Driver',
     'Access To Edit Driver',
     'Access To Approve Driver',
     "Access To View Drivers",
     "Access To View Driver",
     'Access To Add Trip',
     'Access To Delete Trip',
     'Access To Edit Trip',
     'Access To Upload Trips',
     "Access To Download Logs",
     "Access To Assign Trip",
     "Access To View Trips",
     "Access To View Trip",
     'Access To Add Patient',
     'Access To Delete Patient',
     'Access To Edit Patient',
     'Access To View Patients',
     "Access To View Patient",
     "Access To Payrol"
     
     
   ];
   const[isSubAdmin,setIsSubAdmin]=useState(false)
   const[loading,setLoading]=useState(false)
 
   const handleCheckboxChange = (permission) => {
     if (selectedPermissions.includes(permission)) {
       setSelectedPermissions(selectedPermissions.filter(item => item !== permission));
     } else {
      console.log("Select Permissions",[...selectedPermissions,permission])
       setSelectedPermissions([...selectedPermissions, permission]);
     }
   };
   const handleGoBack = () => {
    navigate(-1); // Navigate to the previous page
  };

   const [editingIndex, setEditingIndex] = useState(-1);
 
   const handleAddFeature = () => {
     if (newFeature.trim()) {
       setDummyFeatures([...dummyfeatures, newFeature.trim()]);
       // setFeatures([...dummyfeatures, newFeature.trim()]);
       setNewFeature("");
     }
   };
   let navigate=useNavigate()
 
   const handleEditFeature = (index) => {
     setEditingIndex(index);
     setNewFeature(dummyfeatures[index]);
   };
 
   const handleDeleteFeature = (index) => {
     setDummyFeatures(dummyfeatures.filter((_, i) => i !== index));
     // setFeatures(features.filter((_, i) => i !== index));
   };
 
   const handleFeatureChange = (e) => {
     setNewFeature(e.target.value);
   };
 
   const handleSaveEdit = () => {
     if (newFeature.trim()) {
       const updatedFeatures = [...dummyfeatures];
       updatedFeatures[editingIndex] = newFeature.trim();
       setDummyFeatures(updatedFeatures);
       // setFeatures(updatedFeatures);
       setEditingIndex(-1);
       setNewFeature("");
     }
   };
   useEffect(()=>{
    let decodedUser=jwtDecode(localStorage.getItem("token"))
    if(decodedUser.role=="User")
    {
      setIsSubAdmin(true)
    }

   },[])
   const addUser = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");

    // Create form data to handle both text fields and file upload
    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("EMailAddress", EMailAddress);
    formData.append("companyName", companyName);
    formData.append("phoneNumber", phoneNumber);
    selectedPermissions.forEach(permission => {
      formData.append('accessibilities', permission);  // Key: 'accessibilities'
    });
    console.log("Selected Permissions",selectedPermissions)
    if (profilePhoto) {
      formData.append("profilePhoto", profilePhoto); // Append the profile photo to formData
    }

    try {
      let response = await axios.post(
        "https://api.triphog.net/api/v1/user/adduser",
        formData,
        { headers: { 'Authorization': token, 'Content-Type': 'multipart/form-data' } }
      );
      console.log(response.data);
      if (response.data.success) {
        setLoading(false);
        toast.success("Successfully Added User!", { position: "top-center" });
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      } else {
        setLoading(false);
        toast.error("There Was An Error While Adding User!", { position: "top-center" });
      }
    } catch (error) {
      setLoading(false);
      toast.error("Failed to add user!", { position: "top-center" });
    }
  }; 
   const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfilePhoto(file); // Set the file to the state
      setPreview(URL.createObjectURL(file)); // Create a preview URL for the image
    }
  };
   if(decodedUser.role=="User")
   {
    return (
      <div className="flex items-center justify-center h-screen bg-deepBlue mt-[20px] rounded-md">
        <div className="bg-white p-8 rounded-lg shadow-lg text-center w-full max-w-md">
          <h1 className="text-3xl font-bold text-deepBlue mb-4">Unauthorized</h1>
          <p className="text-gray-700 mb-6">
            You do not have access to this page. Please contact the administrator or go back.
          </p>
          <button
            onClick={handleGoBack}
            className="bg-deepBlue text-white px-4 py-2 rounded-lg hover:bg-deepBlue-dark transition-colors"
          >
            Go Back
          </button>
        </div>
      </div>
    );
   }
   else{
 
 
  return (
    <>
      <div className="laptop:h-screen">
        <ToastContainer/>
        <button style={{display:"flex",flexDirection:"row",marginTop:"10px"}} onClick={()=>{
         navigate(-1) 
        }}>
          <img style={{height:"26px",width:"23px"}} src={require('./left-arrow.png')}/>
          <h5 style={{marginRight:"8px",marginTop:"1px"}}>BACK</h5>
          
          </button>
        <div className="bg-white rounded-[20px] p-[30px] mt-[20px] laptop:mr-[30px]">
          <Link to={"/superadmin/subscribers"}>
            <div className="flex items-center gap-2 laptop:text-lap_d text-m_c font-[600]">
              <h2 className="laptop:text-lap_c text-m_c">Create New User</h2>
            </div>
          </Link>
          <div class="w-full rounded-sm  bg-cover bg-center bg-no-repeat items-center">
            <label
              for="upload_profile"
              style={{ backgroundImage: `url(${preview || 'default_avatar_url'})` }} // Display preview or a default avatar
              class="mx-auto cursor-pointer flex justify-center w-[141px] h-[141px] bg-blue-300/20 rounded-full bg-cover bg-center bg-no-repeat"
            >
              <div class="bg-white/90 rounded-full w-6 h-6 text-center ml-28 mt-4">
                <input
                  type="file"
                  name="photo"
                  id="upload_profile"
                  hidden
                  accept="image/*" // Accept only image files
                  onChange={handlePhotoChange} // Handle file change event
                />
                <label for="upload_profile">
                  <span className="text-[25px] text-deepBlue">
                    <MdLinkedCamera />
                  </span>
                </label>
              </div>
            </label>
          </div>
          <form
            className="text-darkgray laptop:text-lap_b text-[30px]"
            onSubmit={(event) => {
              event.preventDefault();
            }}
          >
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%] ">
                <label className="">First Name</label>
                <br />
                <input
                  type="text"
                  placeholder="Enter your First Name"
                   onChange={(event) => {
                   setFirstName(event.target.value);
                   }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px]">
                <label>Last Name</label>
                <br />
                <input
                  type="text"
                  placeholder="Enter your Last Name"
                   onChange={(event) => {
                     setLastName(event.target.value);
                   }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            </div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%] ">
                <label className="">Your Email</label>
                <br />
                <input
                  type="text"
                  placeholder="jaskolski.brent@gmail.com"
                   onChange={(event) => {
                     setEMailAddress(event.target.value);
                   }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Phone Number</label>
                <br />
                <input
                  type="text"
                  placeholder="546-933-2772"
                   onChange={(event) => {
                     setPhoneNumber(event.target.value);
                   }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            </div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              
             
            </div>
           {loading?<div className="flex justify-center items-center">
              <button
              
                type="submit"
                className="bg-deepBlue text-white laptop:text-lap_c text-m_b cursor-pointer rounded-md py-[7px] px-[40px] mt-[25px]"
              ><CircularProgress style={{color:"white",height:"15px",width:"15px"}} /></button>
            </div>:<div className="flex justify-center items-center">
              <input
              onClick={addUser}
                // onClick={() => {
                //   setSubmit(true);
                // }}
                type="submit"
                className="bg-deepBlue text-white laptop:text-lap_c text-m_b cursor-pointer rounded-md py-[7px] px-[40px] mt-[25px]"
              />
            </div>} 
          </form>
        </div>
      </div>
      <div className="mt-[-100px] bg-[white]">
      <div className="laptop:grid laptop:sticky laptop:top-0 laptop:left-0 laptop:right-0 p-[25px] bg-white">
      <div className="w-[100%] mt-[10px]">
        <div className="laptop:flex float-right text-lap_e cursor-pointer hidden laptop:pt-[50px]">
          <IoNotificationsOutline />
        </div>
       
        
          <div
            
            className="grid grid-cols-[80%_20%] items-center border rounded-lg px-[10px] py-[20px] laptop:text-lap_b text-[35px] mt-[20px]"
          >
            <div className="p-4">
      <h3 className="text-xl font-bold mb-4 mt-10">Select Permissions</h3>
      <div className="flex flex-wrap gap-4">
        {permissions.map((permission, index) => (
          <div key={index} className="flex items-center space-x-2">
            <input
              type="checkbox"
              id={`permission-${index}`}
              value={permission}
              checked={selectedPermissions.includes(permission)}
              onChange={() => handleCheckboxChange(permission)}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
            <label htmlFor={`permission-${index}`} className="text-sm">
              {permission}
            </label>
          </div>
        ))}
      </div>
      <div className="mt-6">
        <h4 className="text-lg font-semibold">Selected Permissions:</h4>
        <ul className="list-disc list-inside mt-2">
          {selectedPermissions.map((permission, index) => (
            <li key={index} className="text-sm">{permission}</li>
          ))}
        </ul>
      </div>
    </div>

        </div>
        </div>
    </div>
      </div>
      {/* {status && (
        <Modal status={status} setStatus={setStatus} message={message} />
      )} */}
    </>
  );
}
};

export default AddNewUser;
