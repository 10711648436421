import React, { useEffect, useState } from "react";
import AdminprofileHeader from "./AdminprofileHeader";
import { RiDeleteBin5Line } from "react-icons/ri";
import { FaPencil } from "react-icons/fa6";
import { GoDotFill } from "react-icons/go";
import { useAllPaymentContext } from "../../../../Providers/SuperAdmin/AllPaymentProvider";
import SingleAdminSideBar from "./SingleAdminSideBar";
import UpdateAdminProvider from "../../../../Providers/SuperAdmin/UpdateAdminProvider";
import { useParams } from "react-router-dom";
import axios from "axios";

const Payment = () => {
  const { loading, allPayments } = useAllPaymentContext();
  const { id } = useParams();
  const [payments, setPayments] = useState([]);
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [filter, setFilter] = useState("Monthly"); // Default to Monthly

  // Function to fetch payments
  let getAdminPayments = async () => {
    let response = await axios.get(
      `https://api.triphog.net/api/v1/superadmin/getadminbyId/${id}`
    );
    setPayments(response.data.admin.payments);
  };

  // Filter payments based on the selected filter
  const filterPayments = () => {
    const now = new Date();
    let filtered = [];

    if (filter === "Weekly") {
      const last7Days = new Date(now.setDate(now.getDate() - 7));
      filtered = payments.filter((payment) => new Date(payment.addedON) >= last7Days);
    } else if (filter === "Monthly") {
      const last30Days = new Date(now.setDate(now.getDate() - 30));
      filtered = payments.filter((payment) => new Date(payment.addedON) >= last30Days);
    } else if (filter === "Yearly") {
      const last365Days = new Date(now.setDate(now.getDate() - 365));
      filtered = payments.filter((payment) => new Date(payment.addedON) >= last365Days);
    }

    setFilteredPayments(filtered);
  };

  // Run the filter whenever the payments or filter change
  useEffect(() => {
    getAdminPayments();
  }, []);

  useEffect(() => {
    filterPayments();
  }, [filter, payments]);

  return (
    <>
      <div className="bg-white rounded-[20px] p-[30px] mt-[20px] laptop:mr-[30px]">
        <AdminprofileHeader />
        <div>
          {/* Filter Dropdown */}
          <select
            className="float-left bg-[#30325E] text-[white] text-[25px] my-[20px] laptop:text-lap_b laptop:py-[8px] laptop:px-[14px] py-[15px] px-[26px] rounded-[10px] cursor-pointer"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            <option value="Weekly">Weekly</option>
            <option value="Monthly">Monthly</option>
            <option value="Yearly">Yearly</option>
          </select>

          {/* Payments Table */}
          <table className="min-w-full divide-y divide-gray-200 overflow-x-auto mt-[20px] border-[2px] border-solid border-[#B0B0B0] rounded-full">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <input type="checkbox" />
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Date
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Amount
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Payment Method
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Payment Status
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>

            {/* Conditional rendering of filtered payments */}
            {filteredPayments && filteredPayments.length > 0 && (
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredPayments.map((payment) => (
                  <tr key={payment._id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <input type="checkbox" />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <h2>{new Date(payment.addedON).toISOString().split("T")[0]}</h2>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <h2>$ {payment.amount}</h2>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">CARD</td>
                    <td className="py-4 px-6 border-b border-gray-200">
                      <span
                        className={`${
                          payment.status === "Failed"
                            ? "text-[#E42346] bg-[#DF367033]"
                            : payment.status === "Paid"
                            ? "text-[#409261] bg-[#E9FFEF]"
                            : "text-[#D98634] bg-[#FFF2DD]"
                        } flex items-center py-2 px-2 rounded-full text-xs justify-center`}
                      >
                        <GoDotFill /> {payment.status}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-[20px] font-medium flex gap-4 pt-[30px]">
                      <FaPencil />
                      <span className="text-[red]">
                        <RiDeleteBin5Line />
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
      <div className="mt-[-80px] bg-white">
        <UpdateAdminProvider>
          <SingleAdminSideBar />
        </UpdateAdminProvider>
      </div>
    </>
  );
};

export default Payment;
