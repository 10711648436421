import React, { createContext, useContext, useState } from 'react';

// Create ReceiverId Context
const ReceiverIdContext = createContext();
// Create a provider component
export const ChatReceiverIdProvider = ({ children }) => {
  const [receiverId, setReceiverId] = useState(null); // Initialize receiverId state

  // Function to update receiverId
  const updateChatReceiverId = (id) => {
    console.log("Setting The Receiver Id",id)
    console.log("RECEIVER ID SET",id)
    setReceiverId(id);
    console.log("Here is the  receiver id",receiverId)
  };

  return (
    <ReceiverIdContext.Provider value={{ receiverId, updateChatReceiverId }}>
      {children}
    </ReceiverIdContext.Provider>
  );
};

// Custom hook to use the ReceiverId Context
export const useChatReceiverId = () => {
  return useContext(ReceiverIdContext);
};
