import React, { useEffect, useState } from "react";
import PaymentHistory from "./PaymentHistory";
import PlanSideBar from "./PlanSideBar";
import {loadStripe} from '@stripe/stripe-js';
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
const Plan = () => {
 const[refreshStatistics,setRefreshStatistics]=useState(false) 
  const[loading,setLoading]=useState(false)
  let decodedUser=jwtDecode(localStorage.getItem("token"))
const[paymentIsDue,setPaymentIsDue]=useState(false)
const[planValidTill,setPlanValidTill]=useState()
let navigate=useNavigate()
  let getPaymentHistory=async()=>{
    setLoading(true)
    let response=await axios.get(`https://api.triphog.net/api/v1/superadmin/admin/getById/${localStorage.getItem("token")}`)
    let payments=response.data.data.payments
    if(payments.length==0)
    {
      setPaymentIsDue(true)
      setLoading(false)
    }
    else{
      const lastPayment = payments[payments.length - 1];
      console.log("Last Payment",lastPayment)
      const lastPaymentDate = new Date(lastPayment.addedON);
      console.log("Last Payment Date",lastPaymentDate)
      const nextDueDate = new Date(lastPaymentDate);
      console.log("Next Due Date",nextDueDate)
      nextDueDate.setMonth(lastPaymentDate.getMonth() + 1);
      console.log("Next Due Date After One Month",nextDueDate)

      const today = new Date();
      console.log("Today Date",today)
      console.log("Is DUe",today>=nextDueDate)
      console.log("Date",nextDueDate.toDateString())
      console.log("Time",nextDueDate.toLocaleTimeString())
    
      setPaymentIsDue(today >= nextDueDate);
    setPlanValidTill(nextDueDate.toDateString()+" "+nextDueDate.toLocaleTimeString())
    setLoading(false)
    }
  }
  useEffect(()=>{
    setLoading(true)
    getPaymentHistory()
  },[])
  const handleGoBack = () => {
    navigate(-1); // Navigate to the previous page
  };
  let makePayment=async()=>
  {
    setLoading(true)
    console.log("Making Payment")
  const stripe=  await loadStripe("pk_test_51N3JXaIt4uj8sEIFyp8aviPUcgeur8tSktgkwQSDlCwV1xgRM6qXhqBQKzp7qAkNenvNGWZGXjVTkrS6mz1i8d4d00Ljiw8inK")
 let response= await axios.post("https://api.triphog.net/api/v1/admin/create-checkout-session")
 console.log(response.data)
 let result=await stripe.redirectToCheckout({sessionId:response.data.id})
 if(result.error)
 {
  console.log("Error While Checkout")
  setLoading(false)
 }
 else{
  setRefreshStatistics(true)
     let response=await axios.put("https://api.triphog.net/api/v1/admin/api/v1/admin/update-payment-history",{},{headers:{"Authorization":localStorage.getItem("token")}})
     console.log(response.data)

  // stripe.on('checkout.session.completed', async (event) => {
  //   let response=await axios.put("https://api.triphog.net/api/v1/admin/api/v1/admin/update-payment-history",{},{headers:{"Authorization":localStorage.getItem("token")}})
  //   console.log(response.data)
  
  //   console.log("Payment successful, API called.");
  // });
  setLoading(false)

 }
  }
  if(decodedUser.role=="User")
  {
    
       return (
         <div className="flex items-center justify-center h-screen bg-deepBlue mt-[20px] rounded-md">
           <div className="bg-white p-8 rounded-lg shadow-lg text-center w-full max-w-md">
             <h1 className="text-3xl font-bold text-deepBlue mb-4">Unauthorized</h1>
             <p className="text-gray-700 mb-6">
               You do not have access to this page. Please contact the administrator or go back.
             </p>
             <button
               onClick={handleGoBack}
               className="bg-deepBlue text-white px-4 py-2 rounded-lg hover:bg-deepBlue-dark transition-colors"
             >
               Go Back
             </button>
           </div>
         </div>
       );
      
  

  }
  else{
  return (
    <>
      <div>
      {loading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <CircularProgress size={60} thickness={5} color="inherit" />
        </div>
      )}
        <div className="bg-white rounded-[20px] p-[30px] mt-[20px] laptop:mr-[30px] payment_page">
          <div className="my-[40px]">
            <div className="flex items-center justify-between my-[10px] plan_page_heading">
              <h2 className="text-lap_c font-[600]">Your Current Plan</h2>
              <h4 className="float-right text-[#80848C] text-lap_b">
                Note: This user trial will expire on {planValidTill}
              </h4>
            </div>
            <div className="flex items-center justify-between border rounded-lg px-[50px] py-[20px] up_plan ">
              <h2 className="text-lap_c text-[#30325E] font-[600]">
                Ultimate Plan
              </h2>
            </div>
          </div>
          <div className="my-[10px]">
            <div className="flex items-center justify-between my-[10px]">
              <h2 className="text-lap_c font-[600]">Your Payments</h2>
            </div>
            <div className="flex items-center justify-between border rounded-lg px-[50px] py-[20px] pending_payment">
              <h2 className="text-lap_c text-[#409261] font-[600]">$30 TO Pay</h2>
             {paymentIsDue?  <div className="float-right text-lap_b">
                <button onClick={makePayment}  className="bg-deepBlue text-white py-[6px] px-[15px] rounded-lg mr-[20px] pay_button">
               Make Payment
                </button>
              </div>:<button>YOU HAVE PAID FOR THIS MONTH</button>}
            
            </div>
          </div>
        </div>
        <PaymentHistory refreshStatistics={refreshStatistics} />
      </div>
      <div className="mt-[-80px]">
        <PlanSideBar />
      </div>
    </>
  );
}
};

export default Plan;
{/* const express = require('express');
const stripe = require('stripe')('YOUR_STRIPE_SECRET_KEY');

const app = express();

app.post('/create-checkout-session', async (req, res) => {
  try {
    const session = await stripe.checkout.sessions.create({
      line_items: [
        {
          price_data: {
            currency: 'usd',
            unit_amount: 3000, // Charge $30.00
            product_data: {
              name: 'Payment to Super Admin',
              description: 'Payment of $30 USD',
            },
          },
          quantity: 1,
           adjustable_quantity: {
            enabled: false,
          },
        },
      ],
      mode: 'payment',
      success_url: 'http://localhost:3000/success',
      cancel_url: 'http://localhost:3000/cancel',
    });

    res.json({ url: session.url });
  } catch (error) {
    console.error(error);
    res.status(500).json({ error: 'Error creating checkout session' });

  }
});
import React, { useEffect, useState } from 'react';

const PaymentComponent = ({ payments }) => {
  const [showPaymentButton, setShowPaymentButton] = useState(false);

  useEffect(() => {
    if (payments.length > 0) {
      const lastPayment = payments[payments.length - 1];
      const lastPaymentDate = new Date(lastPayment.date);
      const nextDueDate = new Date(lastPaymentDate);
      nextDueDate.setMonth(lastPaymentDate.getMonth() + 1);

      const today = new Date();
      setShowPaymentButton(today >= nextDueDate);
    } else {
      setShowPaymentButton(true);
    }
  }, [payments]);

  return (
    <>
      {showPaymentButton && (
        <button onClick={handlePayment}>Make Payment</button>
      )}
    </>
  );
};

export default PaymentComponent;


*/}


