import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import { motion } from "framer-motion";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import TimelineIcon from "@mui/icons-material/Timeline";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DevicesIcon from "@mui/icons-material/Devices";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ChatIcon from "@mui/icons-material/Chat";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

// Import App Image
const appImage = require("./appimage.jpeg");

// Feature List with Updated Icons and Descriptions
const features = [
  { 
    title: "Easy trip uploads", 
    icon: <CloudUploadIcon />,
    description: "Streamline your trip documentation with our one-click upload feature. Quickly and securely submit trip details, reducing administrative workload and minimizing errors."
  },
  { 
    title: "Instant signature capture for patients and drivers", 
    icon: <TimelineIcon />,
    description: "Capture digital signatures instantly, ensuring compliance and creating a clear audit trail for every trip. Reduce paperwork and increase accountability."
  },
  { 
    title: "Automatic Log Creation with GPS timestamp", 
    icon: <AccessTimeIcon />,
    description: "Automatically generate precise trip logs with exact GPS timestamps. Eliminate manual logging and ensure accurate record-keeping for billing and compliance."
  },
  { 
    title: "GPS to track Drivers on Route", 
    icon: <LocationOnIcon />,
    description: "Real-time GPS tracking allows you to monitor driver locations, optimize routes, and ensure patient safety with live, accurate location updates."
  },
  { 
    title: "Patient app", 
    icon: <DevicesIcon />,
    description: "Empower patients with a user-friendly mobile app to track their transportation, communicate with drivers, and manage their medical transit experience."
  },
  { 
    title: "Drivers app", 
    icon: <DevicesIcon />,
    description: "Equip drivers with a comprehensive mobile app for navigation, trip details, digital signatures, and real-time communication with the admin office."
  },
  { 
    title: "Document storage for admin and Drivers", 
    icon: <FolderSharedIcon />,
    description: "Centralized, secure document management system allowing easy access, storage, and retrieval of all trip-related documents for admins and drivers."
  },
  { 
    title: "Remote access for all Team members", 
    icon: <GroupAddIcon />,
    description: "Enable seamless collaboration with remote access capabilities, allowing team members to work efficiently from anywhere, at any time."
  },
  { 
    title: "HR and Payroll", 
    icon: <AttachMoneyIcon />,
    description: "Integrated HR and payroll solutions to streamline employee management, track working hours, and process payments accurately and efficiently."
  },
  { 
    title: "messaging feature to admin office", 
    icon: <ChatIcon />,
    description: "Instant messaging system connecting drivers, patients, and admin staff, facilitating quick communication and resolving issues in real-time."
  },
  { 
    title: "24/7 technical support", 
    icon: <SupportAgentIcon />,
    description: "Round-the-clock technical support to ensure smooth operation, quick issue resolution, and minimal downtime for your transportation services."
  },
];

const LandingPage = () => {
  const [hoveredCard, setHoveredCard] = useState(null);

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeInOut",
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeInOut" } },
  };

  return (
    <Box
      sx={{
        backgroundColor: "white",
        color: "#30325E",
        minHeight: "100vh",
        fontFamily: "Cabin, sans-serif",
      }}
    >
      {/* Header */}
      <AppBar
        position="static"
        sx={{
          backgroundColor: "white",
          color: "#30325E",
          boxShadow: "none",
        }}
      >
        <Toolbar>
          <img
            src={appImage}
            alt="App Logo"
            style={{ height: 40, marginRight: 16, width: 40, borderRadius: 30 }}
          />
          <Box sx={{ flexGrow: 1 }} />
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#30325E",
              color: "white",
              borderRadius: 20,
              paddingX: { xs: 2, sm: 3 },
              marginRight: 2,
              fontFamily: "Cabin",
              fontSize: { xs: "0.6rem", sm: "1rem" },
              "&:hover": { backgroundColor: "#3D3E75" },
            }}
            href="http://www.triphog.net/admin/login"
          >
            Admin Login
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#30325E",
              color: "white",
              borderRadius: 20,
              fontFamily: "Cabin",
              paddingX: { xs: 2, sm: 3 },
              fontSize: { xs: "0.6rem", sm: "1rem" },
              "&:hover": { backgroundColor: "#3D3E75" },
            }}
          >
            Request a Demo
          </Button>
        </Toolbar>
      </AppBar>

      {/* Overview Section */}
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <Box sx={{ padding: 4, textAlign: "center" }}>
          <motion.div variants={itemVariants}>
            <Typography
              variant="h4"
              sx={{
                marginBottom: 2,
                fontFamily: "Cabin, sans-serif",
                fontWeight: "bold",
              }}
            >
              Revolutionizing Non-Emergency Medical Transportation
            </Typography>
          </motion.div>
          <motion.div variants={itemVariants}>
            <Typography
              variant="body1"
              sx={{
                maxWidth: 600,
                margin: "auto",
                fontFamily: "Cabin, sans-serif",
                color: "#5A5A8E",
              }}
            >
              Trip Hog Software is transforming the NEMT industry by offering
              an innovative, all-in-one solution that optimizes scheduling,
              routing, and fleet management. Experience unparalleled convenience
              and efficiency with our advanced platform.
            </Typography>
          </motion.div>
        </Box>
      </motion.div>

      {/* Features Section */}
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <Grid
          container
          spacing={3}
          justifyContent="center"
          sx={{ padding: 4 }}
        >
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
              <div 
                style={{ 
                  position: 'relative', 
                  width: '100%', 
                  height: 240,
                  perspective: '1000px'
                }}
                onMouseEnter={() => setHoveredCard(index)}
                onMouseLeave={() => setHoveredCard(null)}
              >
                <motion.div
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    transformStyle: 'preserve-3d',
                    transform: hoveredCard === index 
                      ? 'rotateY(180deg)' 
                      : 'rotateY(0deg)',
                    transition: 'transform 0.6s',
                  }}
                >
                  {/* Front of the card */}
                  <Card
                    sx={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      backfaceVisibility: 'hidden',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'white',
                      color: "#30325E",
                      border: "1px solid #E0E0E0",
                      borderRadius: 2,
                      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                      transform: 'rotateY(0deg)',
                    }}
                  >
                    <CardContent
                      sx={{
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        flexGrow: 1,
                        padding: 3,
                      }}
                    >
                      {feature.icon}
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "bold",
                          fontFamily: "Cabin, sans-serif",
                          marginTop: 2,
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          display: "-webkit-box",
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {feature.title}
                      </Typography>
                    </CardContent>
                  </Card>

                  {/* Back of the card */}
                  <Card
                    sx={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      backfaceVisibility: 'hidden',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: "#30325E",
                      color: "white",
                      border: "1px solid #E0E0E0",
                      borderRadius: 2,
                      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                      transform: 'rotateY(180deg)',
                    }}
                  >
                    <CardContent
                      sx={{
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        flexGrow: 1,
                        padding: 3,
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: "Cabin, sans-serif",
                          textAlign: "center",
                        }}
                      >
                        {feature.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </motion.div>
              </div>
            </Grid>
          ))}
        </Grid>
      </motion.div>

      {/* Footer */}
      <Box
        sx={{
          textAlign: "center",
          backgroundColor: "#F8F9FA",
          padding: 2,
          marginTop: 4,
        }}
      >
        <Typography
          variant="body2"
          sx={{ fontFamily: "Cabin, sans-serif", color: "#5A5A8E" }}
        >
          © 2024 Trip Hog. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default LandingPage;