import React, { useEffect, useState } from 'react';
import { Avatar, Rating, CircularProgress, IconButton,Dialog,DialogTitle,DialogActions,DialogContent,Button,Typography } from '@mui/material';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';

const TripReviews = () => {
  const [tripReviews, setTripReviews] = useState([]);
  const [adminReviews, setAdminReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredReviews, setFilteredReviews] = useState([]);
  const [filteredAdminReviews, setFilteredAdminReviews] = useState([]);

  const getTripReviews = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    let tripResponse = await axios.get("https://api.triphog.net/api/v1/trip/gettrips", { headers: { 'Authorization': token } });
    let adminReviewsResponse = await axios.get("https://api.triphog.net/api/v1/admin/getallreviews", { headers: { "Authorization": token } });

    let tripReviewsList = [];
    for (let trip of tripResponse.data.trips) {
      if (trip.reviews.length > 0) {
        for (let _review of trip.reviews) {
          tripReviewsList.push({
            _id: _review.ID,
            addedON: _review.addedON,
            text: _review.description,
            addedByPhotoUrl: trip.patientPhotoUrl,
            addedBy: trip.patientName,
            rating: _review.rating,
            images: _review.images,
            tripId:trip._id
          });
        }
      }
    }

    const independentReviewsList = adminReviewsResponse.data.allReviews.map(review => ({
      _id: review._id,
      addedON: review.addedON,
      text: review.description,
      addedBy: review.from,
      rating: review.rating,
    }));

    setTripReviews(tripReviewsList);
    setAdminReviews(independentReviewsList);
    setFilteredReviews(tripReviewsList);
    setFilteredAdminReviews(independentReviewsList);
    setLoading(false);
  };
  const deleteAdminReview=async(reviewId)=>{
    handleOpenDialog()
    try{
      let response=await axios.delete(`https://api.triphog.net/api/v1/admin/deletereview/${reviewId}`)
      if(response.data.success)
      {
        toast.success("Successfully Deleted!")
       let _reviews= filteredAdminReviews.filter((review)=>{return(review._id!=reviewId)})
        setFilteredAdminReviews(_reviews)
        handleCloseDialog()
      }
      else{
        toast.error("Error While Deleting Review!")
      }


    }
    catch(e){
      toast.error("Error While Deleting Review!")


    }
    finally{
      handleCloseDialog()

    }

  }
  const deleteTripReview=async(tripId,reviewId)=>{
    handleOpenDialog()
    try{
      let response=await axios.delete(`https://api.triphog.net/api/v1/trip/deletereview/${tripId}/${reviewId}`)
      console.log("Response Data",response.data)
      if(response.data.success)
      {
        toast.success("Successfully Deleted!")
        setFilteredReviews(filteredReviews.filter((review)=>{return(review._id!=reviewId)}))
        handleCloseDialog() 

      }
      else{
        toast.error("Error While Deleting Review!")

      }
     

    }
    catch(e){
      toast.error("Error While Deleting Review!")

    }
    finally{
      handleCloseDialog()

    }

  }

  useEffect(() => {
    getTripReviews();
  }, []);

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchTerm(searchValue);
    const filteredTrip = tripReviews.filter((review) =>
      review.addedBy.toLowerCase().includes(searchValue) ||
      review.text.toLowerCase().includes(searchValue)
    );
    const filteredAdmin = adminReviews.filter((review) =>
      review.addedBy.toLowerCase().includes(searchValue) ||
      review.text.toLowerCase().includes(searchValue)
    );
    setFilteredReviews(filteredTrip);
    setFilteredAdminReviews(filteredAdmin);
  };
  const [openDialog, setOpenDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleOpenDialog = () => {
    setIsDeleting(true);
    setOpenDialog(true);
    // Simulate an API call or async operation
    setTimeout(() => {
      setIsDeleting(false);
    }, 3000); // Simulate 3 seconds for review deletion
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };


  const handleDeleteReview = async (reviewId) => {
    const token = localStorage.getItem("token");
    await axios.delete(`https://api.triphog.net/api/v1/admin/deletereview/${reviewId}`, {
      headers: { "Authorization": token }
    });
    getTripReviews(); // Reload reviews after deletion
  };

  const renderReviewCard = (_review) => (
    <div className='review-card' style={styles.reviewCard}>
      <div style={styles.cardHeader}>
        <div style={styles.userInfo}>
          <Avatar src={_review.addedByPhotoUrl} style={styles.avatar}>{_review.addedBy[0]}</Avatar>
          <div style={styles.userDetails}>
            <h2 style={styles.username}>{_review.addedBy}</h2>
            <h5 style={styles.date}>{new Date(_review.addedON).toLocaleDateString()}</h5>
          </div>
        </div>
        <Rating style={styles.rating} value={_review.rating} readOnly />
      </div>
      <div style={styles.reviewText}>
        {_review.text}
      </div>
      <div style={styles.deleteButtonContainer}>
        <button style={{backgroundColor:"orange",width:"150px"}} className='rounded-full text-white' onClick={(e)=>{
          e.preventDefault()
          if(_review.tripId)
          {
            deleteTripReview(_review.tripId,_review._id)
          }
          else{
            deleteAdminReview(_review._id)
          }

        }}>
          Delete
        </button>
      </div>
    </div>
  );

  return (
    <div style={styles.container}>
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle style={{ fontWeight: 'bold', textAlign: 'center' }}>Deleting Review</DialogTitle>
        <DialogContent style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: '20px' }}>
          {isDeleting ? (
            <>
              <CircularProgress size={24} style={{ color: '#30325E', marginRight: '10px' }} />
              <Typography variant="h6" style={{ color: '#30325E' }}>Please Wait...Review Is Being Deleted</Typography>
            </>
          ) : (
            <Typography variant="body1" style={{ color: '#4CAF50', textAlign: 'center' }}>Review Deleted Successfully!</Typography>
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center', paddingBottom: '20px' }}>
          <Button onClick={handleCloseDialog} color="primary" variant="contained" style={{ borderRadius: '30px' }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <div style={styles.searchBarContainer}>
        <input
          type="text"
          placeholder="Search reviews"
          value={searchTerm}
          onChange={handleSearch}
          style={styles.searchBar}
        />
      </div>

      {loading ? (
        <div style={styles.loaderContainer}>
          <CircularProgress size={80} thickness={4} style={styles.loader} />
        </div>
      ) : (
        <div style={styles.reviewsContainer}>
          <h2 style={styles.sectionTitle}>Trip Reviews</h2>
          {filteredReviews.length === 0 ? (
            <h3 style={styles.noReviews}>No Reviews Available</h3>
          ) : (
            <div style={styles.scrollableReviews}>
              {filteredReviews.map(renderReviewCard)}
            </div>
          )}

          <h2 style={styles.sectionTitle}>Independent Reviews</h2>
          {filteredAdminReviews.length === 0 ? (
            <h3 style={styles.noReviews}>No Independent Reviews Available</h3>
          ) : (
            <div style={styles.scrollableReviews}>
              {filteredAdminReviews.map(renderReviewCard)}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    width: "100%",
    backgroundColor: "white",
    borderRadius: "15px",
    padding: "20px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    marginTop: "20px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
  },
  searchBarContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "20px",
  },
  searchBar: {
    width: "100%",
    maxWidth: "400px",
    height: "40px",
    borderRadius: "50px",
    paddingLeft: "20px",
    border: "none",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    outline: "none",
    fontSize: "16px",
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "200px",
  },
  loader: {
    color: "#30325E", // Deep blue for loader
  },
  reviewsContainer: {
    marginTop: "20px",
    padding: "10px",
    overflowY: "scroll",
  },
  scrollableReviews: {
    maxHeight: "500px",  // Makes reviews section scrollable when there are many reviews
    overflowY: "auto",
  },
  sectionTitle: {
    marginBottom: "15px",
    fontWeight: "bold",
    color: "#30325E", // Deep blue for section title
    fontSize: "24px",
    textTransform: "uppercase",
    letterSpacing: "1px",
  },
  noReviews: {
    textAlign: "center",
    color: "#888",
  },
  reviewCard: {
    backgroundColor: "#30325E", // Deep blue background for reviews
    color: "white",
    display: "flex",
    flexDirection: "column",
    borderRadius: "15px",
    marginBottom: "20px",
    padding: "20px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    transform: "scale(1)",
    cursor: "pointer",
    '&:hover': {
      transform: "scale(1.05)",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
    }
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  userInfo: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    border: "2px solid white", // Add white border to avatar
  },
  userDetails: {
    marginLeft: "10px",
  },
  username: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "white", // White color for the username
    margin: "0",
  },
  date: {
    fontSize: "14px",
    color: "#E0E0E0", // Lighter gray for the date
    margin: "5px 0 0 0",
  },
  rating: {
    marginTop: "10px",
    color: "#FFEB3B", // Yellow for the rating stars
  },
  reviewText: {
    marginTop: "15px",
    fontSize: "16px",
    color: "#E0E0E0", // Light gray for review text
  },
  deleteButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "10px",
  },
  deleteButton: {
    color: "#FF5733", // Red color for delete button
  },
};

export default TripReviews;
