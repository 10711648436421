import React, { useState } from "react";
import FeedBackSideBar from "./FeedBackSideBar";
import { CiSearch } from "react-icons/ci";
import { Rating } from "@smastrom/react-rating";

const FeedBack = () => {
  const [rating, setRating] = useState(0);
  return (
    <>
      <div className="bg-white h-screen rounded-[20px] p-[30px] mt-[20px] laptop:mr-[30px]">
        <div className="flex items-center justify-between">
          <h3 className="text-lap_c font-semibold">Reviews and Rating</h3>
</div>
</div>
      <div className="mt-[-80px]">
        <FeedBackSideBar />
      </div>
    </>
  );
};

export default FeedBack;
