import React, { useState, useEffect } from "react";
import { CiLock } from "react-icons/ci";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [resetToken, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    if (token) {
      setToken(token);
    }
  }, [token]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Passwords Don't Match", { position: "top-center" });
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(`https://api.triphog.net/api/v1/admin/resetpassword/${token}`, { password });
      if (response.data.success) {
        toast.success("Password Has Been Reset!", { position: "top-center" });
      } else {
        toast.error("There Was An Error While Resetting Password", { position: "top-center" });
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.", { position: "top-center" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col md:flex-row h-screen">
      {/* Left side (image) */}
      <div className="flex-1 flex justify-center items-center md:order-1">
        <img
          src="/uploads/login.jpg"
          alt="login"
          className="hidden md:block max-w-xs w-auto h-auto" // Adjust the size of the image
        />
      </div>

      {/* Right side (form) */}
      <div className="flex-1 flex flex-col justify-center items-center p-4 md:order-2">
        <ToastContainer />
        <div className="bg-white p-6 rounded-lg shadow-md w-full md:w-3/4 lg:w-1/2">
          <h2 className="text-xl laptop:text-2xl font-bold mb-6 text-center">Password Creation</h2>
          <form onSubmit={handleSubmit}>
            {/* Password Input */}
            <div className="mb-4 relative">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 pl-10"
              />
              <span className="absolute inset-y-0 left-0 flex items-center text-gray-400 pl-3">
                <CiLock />
              </span>
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
              >
                {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
              </button>
            </div>

            {/* Confirm Password Input */}
            <div className="mb-6 relative">
              <input
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 pl-10"
              />
              <span className="absolute inset-y-0 left-0 flex items-center text-gray-400 pl-3">
                <CiLock />
              </span>
              <button
                type="button"
                onClick={toggleConfirmPasswordVisibility}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
              >
                {showConfirmPassword ? <FaRegEyeSlash /> : <FaRegEye />}
              </button>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-full bg-deepBlue text-white py-3 rounded-full transition-colors duration-300"
            >
              {loading ? "Creating..." : "Create Password"}
            </button>
          </form>

          {status && (
            <div className={`mt-4 text-center ${status === "success" ? "text-green-600" : "text-red-600"}`}>
              {status}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
