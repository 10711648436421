import React, { useEffect, useState } from "react";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { CiCalendarDate } from "react-icons/ci";
import { FaCarSide } from "react-icons/fa6";
import DashboardIcon from '@mui/icons-material/Dashboard';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PersonIcon from '@mui/icons-material/Person';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import EventIcon from '@mui/icons-material/Event';
import ChatIcon from '@mui/icons-material/Chat';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import RateReviewIcon from '@mui/icons-material/RateReview';
import ListAltIcon from '@mui/icons-material/ListAlt';
import DescriptionIcon from '@mui/icons-material/Description';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import axios from "axios";

import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { toast,ToastContainer } from "react-toastify";

const HomeSideBar = () => {
  const allPages = [
    {
      title: "Dashboard",
      icon: <DashboardIcon />
    },
    {
      title: "View Trips",
      icon: <DirectionsCarIcon />
    },
    {
      title: "View Patients",
      icon: <PersonIcon />
    },
    {
      title: "View Drivers",
      icon: <LocalTaxiIcon />
    },
    {
      title: "Schedule Meeting",
      icon: <EventIcon />
    },
    {
      title: "Chats",
      icon: <ChatIcon />
    },
    {
      title: "Billing History",
      icon: <ReceiptLongIcon />
    },
    {
      title: "Trip Reviews",
      icon: <RateReviewIcon />
    },
    {
      title: "Trip Logs",
      icon: <ListAltIcon />
    },
    {
      title: "Documents",
      icon: <DescriptionIcon />
    },
    {
      title: "Payrol",
      icon: <AttachMoneyIcon />
    },
    {
      title: "View Users",
      icon: <PeopleIcon />
    },
    {
      title: "Settings",
      icon: <SettingsIcon />
    },
  ]
  const navigate = useNavigate();
  const [upcomingMeetings, setUpcomingMeetings] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error handling
  const[frequentlyVisitedPages,setFrequentlyVisitedPages]=useState([])
  
  const token =localStorage.getItem('token')
  
  const getFrequentlyVisitedPages=async()=>{
    setLoading(true)
    try{
    if(!token){
    navigate("/admin/login")
    }
    else{
      let decodedUser=jwtDecode(token)
      let response=await axios.get(`https://api.triphog.net/api/v1/admin/getsingleadmin/${decodedUser.role=="Admin"?decodedUser.id:decodedUser.createdBy}`)
      if(response.data.succcess==false)
      {
        toast.error("Erorr While Getting Frequently Visited Pages")
      }
      else{
        setFrequentlyVisitedPages(response.data.admin.frequentlyVisitedPages)
      }
    }
      

    }
    catch(e){
      toast.error("Erorr While Getting Frequently Visited Pages")


    }
    finally{
      setLoading(false)
    }
  }

  const getUpcomingMeetings = async () => {
    setLoading(true); // Start loading
    setError(null); // Reset error

    try {
      let response = await axios.get(
        "https://api.triphog.net/api/v1/meeting/getmeetings",
        {
          headers: { Authorization: localStorage.getItem("token") },
        }
      );

      console.log("My Meetings As Admin", response.data.meetings);

      const now = new Date();

      // Filter and create a full datetime object for sorting
      const upcomingMeetings = response.data.meetings
        .filter((meeting) => {
          const meetingDateTime = new Date(meeting.date);
          const [hours, minutes] = meeting.time.split(":").map(Number);
          meetingDateTime.setHours(hours, minutes, 0, 0);

          return meetingDateTime > now; // Keep meetings that are in the future
        })
        .sort((a, b) => {
          const dateA = new Date(a.date);
          const [hoursA, minutesA] = a.time.split(":").map(Number);
          dateA.setHours(hoursA, minutesA, 0, 0);

          const dateB = new Date(b.date);
          const [hoursB, minutesB] = b.time.split(":").map(Number);
          dateB.setHours(hoursB, minutesB, 0, 0);

          return dateA - dateB; // Sort by date and time
        });

      setUpcomingMeetings(upcomingMeetings);
    } catch (error) {
      console.error("Error fetching meetings:", error);
      setError("Failed to load meetings.");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    if(!token)
    {
      navigate("/admin/login")
    }
    else{
      getUpcomingMeetings();
    getFrequentlyVisitedPages()

    }
    
  }, []);
  const getIcon=(title)=>{
    return(allPages.filter((page)=>{
      return(page.title==title)
    })[0].icon)
  }

  return (
    <div className="laptop:grid hidden laptop:sticky laptop:top-0 laptop:left-0 laptop:right-0 p-[25px] bg-white rounded-3xl mt-[20px] shadow-md">
      {/* Upcoming Meetings Section */}
      <div className="w-[100%]">
        <h2 className="text-xl font-bold mt-[20px] mb-[15px] text-gray-900">
          Upcoming Meetings
        </h2>

        {/* Loader Spinner */}
        {loading ? (
          <div className="flex justify-center items-center h-[200px]">
            <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-b-4 border-deepBlue"></div>
          </div>
        ) : error ? (
          <p className="text-red-500">{error}</p>
        ) : (
          <div
            style={{
              maxHeight: "300px",
              overflowY: "auto",
              scrollbarWidth: "none", // Firefox
              msOverflowStyle: "none", // IE and Edge
            }}
            className="overflow-y-auto"
          >
            {/* Webkit Scrollbar for Chrome, Safari and Edge */}
            <style>{`
              .scrollbar-hide::-webkit-scrollbar {
                display: none;
              }
            `}</style>
            {upcomingMeetings.length > 0 ? (
              upcomingMeetings.map((meeting) => (
                <div
                  key={meeting._id}
                  className="border border-gray-200 p-4 rounded-lg shadow-sm bg-white hover:shadow-lg transition-shadow duration-300"
                >
                  <h3 className="font-bold text-sm mb-2 text-gray-800 truncate">
                    {meeting.title}
                  </h3>

                  <div className="mb-2 text-gray-600 flex items-center justify-between">
                    <p className="text-sm">
                      <strong>Date:</strong>{" "}
                      {new Date(meeting.date).toLocaleDateString()}
                    </p>
                    <p className="text-sm">
                      <strong>Time:</strong> {meeting.time}
                    </p>
                  </div>

                  <div className="mb-2 text-gray-600">
                    <p className="text-sm truncate">
                      <strong>Location:</strong> {meeting.location}
                    </p>
                    <p className="text-sm truncate">
                      <strong>With:</strong> {meeting.scheduleWith}
                    </p>
                  </div>

                  <p className="text-sm text-gray-600 truncate">
                    <strong>Notes:</strong> {meeting.notes || "No notes"}
                  </p>
                </div>
              ))
            ) : (
              <p className="text-gray-500">No upcoming meetings for today.</p>
            )}
          </div>
        )}
      </div>

      {/* Action Buttons */}
      <div className="my-[40px]">
        {
          frequentlyVisitedPages.map((page)=>{
            return(
              <div
          onClick={() => navigate(page.path)}
          className="flex cursor-pointer gap-[20px] items-center my-[10px] rounded-md border p-[20px] hover:bg-gray-100 transition-colors"
        >
          <h2 className="text-lap_e">
            {getIcon(page.title)}
          </h2>
          <h2 className="text-black text-lg">{page.title}</h2>
        </div>

            )
          })
        }
        

      </div>
    </div>
  );
};

export default HomeSideBar;
