import React, { useEffect, useState } from "react";
import Modal from "../../../Modal";
import SingleAdminSideBar from "./SingleAdminSideBar";
import { AiFillEdit } from "react-icons/ai"; // Assuming you have an icon library installed
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast,ToastContainer } from "react-toastify";

const UpdateAdmin = () => {
    const{id}=useParams()
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyCode, setCompanyCode] = useState("");
  const [status, setStatus] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [profilePhoto, setProfilePhoto] = useState(null);
  const[photo,setPhoto]=useState(null)
  const[isLoading,setIsLoading]=useState(true)
  const[isUpdating,setIsUpdating]=useState(false)

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
        setPhoto(file)
      setProfilePhoto(URL.createObjectURL(file));
    }
  };

 
  let updateAdmin=async()=>{
    setIsUpdating(true)
    try{
    const formData=new FormData()
    formData.append("firstName",firstName)
    formData.append("lastName",lastName)
    formData.append("email",email)
    formData.append("companyName",companyName)
    formData.append("companyCode",companyCode)
    formData.append("phoneNumber",phoneNumber)
    if(photo){
        formData.append("profilePhoto",photo)
    }
    let response=await axios.put(`https://api.triphog.net/api/v1/superadmin/admin/update/${id}`,formData,{headers:{"Content-Type":"multipart/form-data"}})
    if(response.status==200)
    {
        toast.success("Admin Successfully Updated!",{style:{fontFamily:"Cabin"}})
        setIsUpdating(false)
    }
    else{
        toast.error("Admin Could Not Be Deleted!Please Try Again")
        setIsUpdating(false)
    }
    }
    catch(e){
        toast.error("Admin Could Not Be Deleted!Please Try Again")
        setIsUpdating(false)
    }
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    updateAdmin()
  }
  let getAdminById=async()=>{
    try{
        console.log("Getting Admin Data")
        let response=await axios.get(`https://api.triphog.net/api/v1/superadmin/getadminbyId/${id}`)
        console.log(response.data)
        setFirstName(response.data.admin.firstName)
        setLastName(response.data.admin.lastName)
        setCompanyCode(response.data.admin.companyCode)
        setCompanyName(response.data.admin.companyName)
        setEmail(response.data.admin.email)
        setPhoneNumber(response.data.admin.phoneNumber)
        setProfilePhoto(response.data.admin.photo)
        setIsLoading(false)
    }
    catch(e){
        console.log("Error While Getting Admin Data",e.message)
        toast.error("Could Not Get Admin.Please Try To Refresh The Page")
        setIsLoading(false)
    }
  }
  const navigate=useNavigate()
  useEffect(()=>{
    getAdminById()

  },[])
  if(isLoading)
  {
    return(<div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <div
          style={{
            width: '50px',
            height: '50px',
            border: '5px solid #30325E', // Light transparent deep blue
            borderTop: '5px solid white',           // Solid deep blue
            borderRadius: '50%',
            animation: 'spin 1s linear infinite',
          }}
        ></div>
        <style>
          {`
            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          `}
        </style>
      </div>)
  }
  else{

  return (
    <>
      <div className="bg-white rounded-[20px] p-[30px] mt-[20px] mr-[30px]">
        {/* Profile Photo Avatar */}
        <button
        onClick={()=>{
            navigate(-1)
        }}
              
            
                className="bg-deepBlue font-bold text-white px-4 py-2 rounded-md mt-4"
              >
              Back
              </button>
        <div className="flex justify-center items-center mb-5">
          <div className="relative">
            <img
              src={profilePhoto || "https://via.placeholder.com/150"} // Default placeholder image
              alt="Profile Avatar"
              className="w-32 h-32 rounded-full object-cover border-4 border-deepBlue"
            />
            <label className="absolute bottom-0 right-0 p-2 bg-deepBlue rounded-full cursor-pointer">
              <AiFillEdit className="text-white" size={24} />
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className="hidden"
              />
            </label>
          </div>
        </div>

        <div>
          <form className="text-darkgray laptop:text-lap_b" onSubmit={handleSubmit}>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>First Name</label>
                <br />
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px]">
                <label>Last Name</label>
                <br />
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            </div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Email</label>
                <br />
                <input
                disabled
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Phone Number</label>
                <br />
                <input
                  type="text"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            </div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Company Name</label>
                <br />
                <input
                disabled
                  type="text"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Company Code</label>
                <br />
                <input
                disabled
                  type="text"
                  value={companyCode}
                  onChange={(e) => setCompanyCode(e.target.value)}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            </div>
            <div className="laptop:flex laptop:justify-center laptop:items-center">
              <button
              disabled={isUpdating}
                type="submit"
                className="bg-deepBlue font-bold text-white px-4 py-2 rounded-md mt-4"
              >
              {isUpdating?"Saving Changes":"Save Changes"}
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="bg-white mt-[-80px]">
        <SingleAdminSideBar />
      </div>

      {status && (
        <Modal status={status} setStatus={setStatus} message={modalMessage} />
      )}
    </>
  );
}
};

export default UpdateAdmin;
