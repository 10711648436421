import React, { useState, useEffect } from "react";
import { CiLock } from "react-icons/ci";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import { useCreatePasswordContext } from "../../../Providers/SuperAdmin/CreatePasswordProvider";

const CreatePassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams();

  const {
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    setToken,
    createPassword,
    loading,
    status,
    message,
  } = useCreatePasswordContext();

  useEffect(() => {
    if (token) {
      setToken(token);
    }
  }, [token, setToken]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await createPassword();
    if (status === "success") {
      // Redirect to login page or dashboard after successful password creation
      navigate("/superadmin");
    }
  };

  return (
    <div className="flex flex-col lg:flex-row h-screen">
      {/* Left side (logo and image) */}
      <div className="lg:flex-1 flex items-center justify-center bg-gray-100">
        <img
          src="/uploads/login.jpg"
          alt="login"
          className="w-full h-auto object-cover sm:h-[300px] md:h-[400px] lg:h-full"
        />
      </div>

      {/* Right side (form) */}
      <div className="flex flex-1 flex-col justify-center items-center lg:mt-0 mt-6">
        <div className="bg-white p-8 rounded-lg w-full max-w-[500px]">
          <h2 className="text-xl lg:text-2xl font-bold mb-6 text-center">
            Password Creation
          </h2>
          <form onSubmit={handleSubmit}>
            {/* Password field */}
            <div className="mb-4 relative text-lg lg:text-xl">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 pl-12"
              />
              <span className="absolute inset-y-0 left-0 flex items-center text-gray-400 pl-4">
                <CiLock size={24} />
              </span>
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-0 pr-4 flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
              >
                {showPassword ? <FaRegEyeSlash size={24} /> : <FaRegEye size={24} />}
              </button>
            </div>

            {/* Confirm password field */}
            <div className="mb-4 relative text-lg lg:text-xl">
              <input
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 pl-12"
              />
              <span className="absolute inset-y-0 left-0 flex items-center text-gray-400 pl-4">
                <CiLock size={24} />
              </span>
              <button
                type="button"
                onClick={toggleConfirmPasswordVisibility}
                className="absolute inset-y-0 right-0 pr-4 flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
              >
                {showConfirmPassword ? (
                  <FaRegEyeSlash size={24} />
                ) : (
                  <FaRegEye size={24} />
                )}
              </button>
            </div>

            {/* Submit button */}
            <button
              type="submit"
              disabled={loading}
              className="w-full bg-deepBlue text-white py-2 lg:py-3 rounded-full transition-colors duration-300 text-lg lg:text-xl"
            >
              {loading ? "Creating..." : "Create Password"}
            </button>
          </form>

          {status && (
            <div
              className={`mt-4 text-center ${
                status === "success" ? "text-green-600" : "text-red-600"
              }`}
            >
              {message}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreatePassword;
