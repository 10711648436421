import React from 'react';
import { Box, Typography } from '@mui/material';

const PaymentFailure = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      textAlign="center"
    >
      <Typography variant="h4" color="red">
        Payment Failed! ❌
      </Typography>
    </Box>
  );
};

export default PaymentFailure;
