import React, { createContext, useContext, useState } from 'react';

// Create ReceiverId Context
const ReceiverIdContext = createContext();

// Create a provider component
export const ReceiverIdProvider = ({ children }) => {
  const [receiverId, setReceiverId] = useState(null); // Initialize receiverId state

  // Function to update receiverId
  const updateReceiverId = (id) => {
    console.log("Setting The Receiver Id",id)
    setReceiverId(id);
    console.log("Here is the receiver id",receiverId)
  };

  return (
    <ReceiverIdContext.Provider value={{ receiverId, updateReceiverId }}>
      {children}
    </ReceiverIdContext.Provider>
  );
};

// Custom hook to use the ReceiverId Context
export const useReceiverId = () => {
  return useContext(ReceiverIdContext);
};
