import axios from "axios";
import React, { useState, useEffect } from "react";
import { CiLock } from "react-icons/ci";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateDriverPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const[_token,setToken]=useState("")

  useEffect(() => {
    if (token) {
      setToken(token);
    }
  }, [token]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Passwords do not match!", { position: "top-center" });
    } else {
      setLoading(true);
      try {
        let response = await axios.post(
          `https://api.triphog.net/api/v1/driver/createpassword/${_token}`,
          { password, confirmPassword }
        );
        if (response.data.success) {
          toast.success("Password has been created!", { position: "top-center" });
          navigate("/login");
        } else {
          toast.error("There was an error creating the password.", {
            position: "top-center",
          });
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again.", {
          position: "top-center",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="flex h-screen flex-col laptop:flex-row">
      {/* Left side (logo and image) */}
      <div className="flex-1 flex flex-col justify-center items-center bg-gray-100 laptop:h-screen">
        <img
          src="/uploads/login.jpg"
          alt="Login"
          className="hidden laptop:block laptop:h-full laptop:w-auto"
        />
      </div>

      {/* Right side (form) */}
      <div className="flex-1 flex flex-col justify-center items-center laptop:py-12 py-8 bg-white">
        <div className="w-full max-w-lg px-8">
          <h2 className="text-center font-bold laptop:text-2xl text-lg mb-6">
            Create Driver Password
          </h2>
          <form onSubmit={handleSubmit}>
            {/* Password input */}
            <div className="relative mb-8 text-xl laptop:text-base">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-3 laptop:py-2 border border-deepBlue rounded-lg focus:outline-none focus:ring-2 focus:ring-deepBlue pl-12 laptop:pl-10"
              />
              <span className="absolute inset-y-0 left-0 flex items-center pl-4 laptop:pl-2 text-gray-400 text-2xl laptop:text-lg">
                <CiLock />
              </span>
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-0 pr-4 flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
              >
                {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
              </button>
            </div>

            {/* Confirm Password input */}
            <div className="relative mb-8 text-xl laptop:text-base">
              <input
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full px-4 py-3 laptop:py-2 border border-deepBlue rounded-lg focus:outline-none focus:ring-2 focus:ring-deepBlue pl-12 laptop:pl-10"
              />
              <span className="absolute inset-y-0 left-0 flex items-center pl-4 laptop:pl-2 text-gray-400 text-2xl laptop:text-lg">
                <CiLock />
              </span>
              <button
                type="button"
                onClick={toggleConfirmPasswordVisibility}
                className="absolute inset-y-0 right-0 pr-4 flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
              >
                {showConfirmPassword ? <FaRegEyeSlash /> : <FaRegEye />}
              </button>
            </div>

            {/* Submit button */}
            <button
              type="submit"
              disabled={loading}
              className="w-full bg-deepBlue text-white py-3 laptop:py-2 rounded-lg transition duration-300 hover:bg-deepBlue focus:outline-none laptop:text-base text-xl"
            >
              {loading ? "Creating..." : "Create Password"}
            </button>
          </form>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default CreateDriverPassword;
