import { useState } from "react"
import axios from 'axios'
import { ToastContainer,toast } from "react-toastify"
function ForgotPassword()
{
    const[loading,setLoading]=useState(false)
    const[EMailAddress,setEMailAddress]=useState("")
    return(<div className="flex flex-col h-[650px] justify-center items-center">
        <h1 className="mb-auto mt-[60px] font-bold text-[60px] text-[#30325E]">TRIGHOG</h1>
        
         <div className=" flex flex-col mb-[160px] justify-center  items-center h-[300px] w-full md:w-1/2 lg:w-1/3 xl:w-1/3 ">
        <h1 className="text-center mb-[10px]">Forgot Password</h1>
        <input type="text"  className="w-[97%] px-2 py-2  border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 laptop:pl-[40px] pl-[90px]" placeholder="Email Address" onChange={(e)=>{
            setEMailAddress(e.target.value)
        }}/>
        <ToastContainer/>
        <button disabled={loading}  style={{borderRadius:"10px",fontSize:"13px",margin:"auto",marginTop:"15px", width:"210px",height:"38px",backgroundColor:"#30325E",color:"white"}}  onClick={()=>{
            async function forgotPassword()
            {
                setLoading(true)
                try{
                let response=await axios.post("https://api.triphog.net/api/v1/admin/forgotpassword",{EMailAddress})
                console.log(response.data)
                if(response.data.success)
                {
                    toast.success("Check Your EMail.We Have Sent A Link To Reset Password",{position:"top-center"})
                
setLoading(false)                }
                else
                {
                    setLoading(false)
                    toast.error("There Was An Error While Sending Link",{position:"top-center"})
                }
                }
                catch(e){
                    setLoading(false)
                    toast.error("Something Went Wrong!")
                }

            }
            forgotPassword()
                  
                }} >{loading?"Submitting...":"Submit"}</button>
    </div></div>)
}
export default ForgotPassword