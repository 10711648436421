import axios from "axios";
import React, { useState, useEffect } from "react";
import { CiLock } from "react-icons/ci";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const CreatePatientPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { token } = useParams();
  const [_token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (token) {
      setToken(token);
    }
  }, [token]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Passwords Do Not Match!", { position: "top-center" });
    } else {
      setLoading(true);
      try {
        let response = await axios.post(`https://api.triphog.net/api/v1/patient/createpassword/${_token}`, { password, confirmPassword });
        if (response.data.success) {
          toast.success("Password Has Been Created!", { position: "top-center" });
        } else {
          toast.error("There Was An Error While Creating Password!", { position: "top-center" });
        }
      } catch (error) {
        toast.error("There Was An Error While Creating Password!", { position: "top-center" });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Left side (logo and image) */}
      <div className="hidden laptop:flex flex-1 justify-center items-center bg-gray-200">
        <img src="/uploads/login.jpg" alt="login" className="max-w-full h-auto rounded-lg" />
      </div>

      {/* Right side for password input */}
      <div className="flex-1 flex flex-col justify-center items-center p-8">
        <div className="bg-white p-8 rounded-lg shadow-lg w-[90%] max-w-md">
          <h2 className="text-xl font-bold mb-6 text-center">Password Creation</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4 relative">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 pl-10"
              />
              <span className="absolute inset-y-0 left-0 flex items-center text-gray-400 pl-3">
                <CiLock />
              </span>
              <ToastContainer />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
              >
                {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
              </button>
            </div>
            <div className="mb-6 relative">
              <input
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 pl-10"
              />
              <span className="absolute inset-y-0 left-0 flex items-center text-gray-400 pl-3">
                <CiLock />
              </span>
              <button
                type="button"
                onClick={toggleConfirmPasswordVisibility}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
              >
                {showConfirmPassword ? <FaRegEyeSlash /> : <FaRegEye />}
              </button>
            </div>

            <button
              type="submit"
              disabled={loading}
              className="w-full bg-deepBlue text-white py-2 rounded-full transition-colors duration-300 text-lg"
            >
              {loading ? "Creating..." : "Create Password"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreatePatientPassword;
