import React, { useEffect, useState } from "react";
import { FiDollarSign, FiUserCheck } from "react-icons/fi";
import { SiTicktick } from "react-icons/si";
import axios from "axios";
import { CircularProgress,Box } from "@mui/material";
const Progress = () => {
  const[adminStatistics,setAdminStatistics]=useState([])
  let getAdminStatistics=async()=>{
    let response=await axios.get("https://api.triphog.net/api/v1/superadmin/getadminstatistics")
    console.log("Admin Statistics")
    console.log(response.data)
    setAdminStatistics(response.data)
  }
  useEffect(()=>{
    getAdminStatistics()
  },[])
  if(Object.keys(adminStatistics).length==0)
  {
    return(<Box className="flex flex-col justify-center items-center">
      <CircularProgress style={{color:"#30325E",height:"18px",width:"18px"}}/>
    </Box>)
  }
  else{
  return (
    <div className="bg-white rounded-[20px] p-[20px] mt-[20px] laptop:mr-[30px]">
    
      <div className="laptop:grid laptop:grid-cols-3 laptop:gap-[30px] laptop:mt-[20px] grid grid-cols-1 gap-[25px] mt-[25px]">
        <div className="grid grid-cols-[80%_auto] items-center bg-[#DF3670] rounded-[20px] laptop:p-[30px] p-[70px]">
          <div>
            <h3 className="laptop:text-lap_b text-[40px] font-[500] text-[white]">
              Total Payment
            </h3>
            <h1 className="text-[55px] laptop:text-lap_d font-[700] text-[white]">
            ${adminStatistics.receivedPayment}/${adminStatistics.totalPayment}
            </h1>
          </div>
          <div className="text-[#ffffff85] laptop:text-[50px] text-[90px] font-[700]">
            <FiDollarSign />
          </div>
        </div>
        <div className="grid grid-cols-[80%_auto] items-center rounded-[20px] laptop:p-[30px] p-[70px] bg-[#4F3FF0] ">
          <div>
            <h3 className="laptop:text-lap_b text-[40px] font-[500] text-[white]">
              Paid Subscription
            </h3>
            <h1 className="text-[55px] laptop:text-lap_d font-[700] text-[white]">
            {adminStatistics.paidAdminsCount}/ {adminStatistics.totalAdmins}
            </h1>
          </div>
          <div className="text-[#ffffff85] laptop:text-[50px] text-[90px] font-[700]">
            <SiTicktick />
          </div>
        </div>
        <div className="grid grid-cols-[80%_auto] items-center rounded-[20px] laptop:p-[30px] p-[70px] bg-[#096C86] ">
          <div>
            <h3 className="laptop:text-lap_b text-[40px] font-[500] text-[white]">
              New Subsciptions
            </h3>
            <h1 className="text-[55px] laptop:text-lap_d font-[700] text-[white]">
              {adminStatistics.newAdminsByMonth.length}
            </h1>
          </div>
          <div className="text-[#ffffff85] laptop:text-[50px] text-[90px] font-[700]">
            <FiUserCheck />
          </div>
        </div>
      </div>
    </div>
  );
}
};

export default Progress;
