import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom"; // Import BrowserRouter
import "./index.css";
import App from "./App";
import { SocketProvider } from "./components/SocketContext/SocketContext";
import { ReceiverIdProvider } from "./components/Admin/Chat/ChatReceiverContext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  
  <BrowserRouter> {/* Wrap App with BrowserRouter */}
  <SocketProvider>
    <ReceiverIdProvider>
    <App />
    </ReceiverIdProvider>
</SocketProvider>
  </BrowserRouter>
  

)
/*
ICE Candidate
Offer
Answer
Stun/Turn Server

Signaling Server

peer.setLocalDescription()
peer.setRemoteDescriptino()
RTCConnection
RTCSession

Every device will connect to the stun/turn server to get its' public ip address which is know as
ICE Candidate. If we want to create a peer to peer connection between two devices,then their
ICE Candiadates are exhchanged. If a user wants to call another user,he will create offer. signaling server
will be notified about the offer and it will send the off to the receiver peer. receiver peer
will accept the offer and will give an answer in return. that answer is first given to the 
signaling server and then the signaling server gives that answer to the sender peer

Live Chat,Live Streaming,Meeting Host,Removing Members,Screen Sharing Feature
300k
*/