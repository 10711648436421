import React, { useEffect, useState } from "react";
import { AiOutlineMessage } from "react-icons/ai";
import { BsXSquare } from "react-icons/bs";
import { CiLogin, CiUser } from "react-icons/ci";
import { IoSettingsOutline } from "react-icons/io5";
import {
  MdArrowForwardIos,
  MdOutlineCalendarMonth,
  MdSpaceDashboard,
} from "react-icons/md";
import { RiUserFollowLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import appimage from './appimage.jpeg'
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { CircularProgress } from "@mui/material";

const MobileNavBar = ({ menu, setMenu }) => {
  

  const [admin, setAdmin] = useState({});
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  console.log("Token Found", token);
  const[decodedUser,setDecodedUser]=useState({})
  let _decodedUser={}
  const[loading,setLoading]=useState(false)


  useEffect(() => {
    setLoading(true)
    const fetchAdminData = async () => {
      try {
        if (!token) {
          navigate("/admin/login");
        } else {
          try {
            _decodedUser = jwtDecode(token);
            setDecodedUser(_decodedUser)
          } catch (error) {
            console.error("Invalid token:", error);
            navigate("/admin/login");
            return;
          }

          if (_decodedUser?.role === "Admin") {
            try {
              const response = await axios.get(
                `https://api.triphog.net/api/v1/superadmin/admin/getById/${token}`,{
                  headers:{
                    "Authorization":token
                  }
                }
              );
              console.log("Admin Data", response.data);
              setAdmin(response.data.data);
            } catch (error) {
              console.error("Error fetching admin data:", error);
            }
          } else {
            console.warn("User is not an admin");
            navigate("/admin/login");
          }
        }
      } catch (error) {
        console.error("Unexpected error:", error);
        navigate("/admin/login");
      }
      finally{
        setLoading(false)
      }
    };

    fetchAdminData();
  }, [token, navigate]);



  
  return (
    <div
      className="fixed top-0 left-0 w-full h-screen bg-black bg-opacity-50 z-10 ransition-transform duration-3000 ease-in-out 
       translate-x-0"
    >
      <div className="fixed top-0 left-0 h-screen w-1/2 bg-white z-20 py-[20px] px-[30px] mb_nav">
       {loading?<div className="flex flex-col justify-center items-center"> <CircularProgress sx={{color:"black"}}   /></div>: <div className="flex justify-between items-center">
          <div className="bg-[#C8F7DC] h-[100px] w-[100px] rounded-full">
            <img
              className="h-[100px] w-[100px]"
              src={decodedUser.role=="Admin"?admin.photo:decodedUser.profilePhotoUrl}
              // src="https://s3-alpha-sig.figma.com/img/098c/cde0/8799f08faa40145a018b2cf2c8d3c214?Expires=1717372800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MMKvMK7zb6q9LLRxCLqaIL0-TihGBquH30NZEOn12o7Gc3l3FxtYxtoaPkGvC7ZX6Tt7JR1B9SRPwiIeACnat3kNpWZr1LQVuqH67D3Lx7zr3psO08xVlrNkT1ETUpfmyc~kdZPnAg3eLcVpS9qmFp4u7~O6G-AsPtyyt5yjHe5yXyQWPQAklJ6D4hGaOAhpN4HcA-7Kz~gm5ERwCAjiEwBdt0CQ~HiPjZvkhhxJIH1pbZLIDlmr22ZEz7P-JdQCBojybvIopFu3Td-sF3ge8U7rIyKnkmStxY0tRGjoblOabTGGBhz1bGztURxb77Xx44RwU~S0m1~dhHjPXL5REA__"
              alt="profile"
              style={{borderRadius:"50%"}}
            />
          </div>
          <h3 className="text-[20px] text-[#262626] font-[700] user_name" >
            
            {decodedUser.role=="Admin"?admin.firstName +" "+admin.lastName:decodedUser.fullName}👋{" "}
          </h3>
          <div
            className="float-right text-[50px] text-deepBlue "
            onClick={() => {
              setMenu(!menu);
            }}
          >
            <BsXSquare />
          </div>
        </div>}
  
        <div className="grid mt-[40px] mobile_sidebar_toggle">
          <Link
            className="nv_item"
            to={"/admin"}
            onClick={() => {
              setMenu(false);
            }}
          >
            <div onClick={()=>{
              navigate("/admin")
            }} className="flex items-center justify-between text-m_b my-[15px]">
              <h2 className="text-darkgray">Dashboard</h2>
              <div className="text-deepBlue">
                <MdSpaceDashboard />
              </div>
            </div>
          </Link>
          <Link
            className="nv_item"
            to={"/admin/users"}
            onClick={() => {
              setMenu(false);
            }}
          >
            <div onClick={()=>{
              navigate("/admin/users")
            }} className="flex items-center justify-between text-m_b my-[15px]">
              <h2 className="text-darkgray">Users</h2>
              <div className="text-deepBlue">
                <RiUserFollowLine />
              </div>
            </div>
          </Link>
          <div onClick={()=>{
            navigate("/admin/meeting")
          }} className="flex items-center justify-between text-m_b my-[15px] nv_item">
            <h2  className="text-darkgray">Meeting</h2>
            <div className="text-deepBlue">
              <MdOutlineCalendarMonth />
            </div>
          </div>
          <div onClick={()=>{
            navigate("/admin/chat")
          }} className="flex items-center justify-between text-m_b my-[15px] nv_item">
            <h2 className="text-darkgray">Chats</h2>
            <div className="text-deepBlue">
              <AiOutlineMessage />
            </div>
          </div>
          <div onClick={()=>{
            navigate("/admin/settings")
          }} className="flex items-center justify-between text-m_b my-[15px] nv_item">
            <h2 className="text-darkgray">Settings</h2>
            <div className="text-deepBlue">
              <IoSettingsOutline />
            </div>
          </div>
        </div>
        <div>
          <div className="flex items-center justify-between text-m_b my-[15px] mt-[150%]">
            <h2 className="text-[red]">Log Out</h2>
            <div className="text-deepBlue">
              <CiLogin />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileNavBar;
