import React, { useState } from "react";

import { useUpdateAdminContext } from "../../../../Providers/SuperAdmin/UpdateAdminProvider";
import AdminprofileHeader from "./AdminprofileHeader";
import SingleAdminSideBar from "./SingleAdminSideBar";
import Modal from "../../../Modal";
import axios from "axios";
import { toast } from "react-toastify";

const SingleAdmin = () => {
  const {
    firstName,
    companyCode,
    companyName,
    lastName,
    email,
    phoneNumber,
    role,
    gender,
    setFirstName,
    setLastName,
    setEmail,
    setPhoneNumber,
    setRole,
    setGender,
    setSubmit,
    status,
    setStatus,
    adminId,
    modalMessage,
    loading,
  } = useUpdateAdminContext();


  const[isSendingLink,setIsSendingLink]=useState(false)
  const sendPasswordLink=async()=>{
    setIsSendingLink(true)
    try{
      let response=await axios.post(`https://api.triphog.net/api/v1/superadmin/admin/sendpasswordlink/${adminId}`)
      if(response.data.success){
        toast.success("Password  Link Has Been Sent!")
      }
      else{
        toast.error("Password  Link Could Not Be Sent!Please Try Again")
      }
    }
    catch(e){
      toast.error("Password  Link Could Not Be Sent!Please Try Again")


    }
    finally{
      setIsSendingLink(false)
    }
  }

  return (
    <>
      <div className="bg-white rounded-[20px] p-[30px] mt-[20px] mr-[30px]">
        <AdminprofileHeader />
        <div>
          <form
            className="text-darkgray laptop:text-lap_b"
            
          >
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label className="">First Name</label>
                <br />
                <input
                  type="text"
                  value={firstName}
                  disabled
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px]">
                <label>Last Name</label>
                <br />
                <input
                  type="text"
                  value={lastName}
                  disabled
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            </div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label className="">Your Email</label>
                <br />
                <input
                  type="text"
                  value={email}
                  disabled
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Phone Number</label>
                <br />
                <input
                disabled
                  type="text"
                  value={phoneNumber}
                  
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            </div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label className="">Company Name</label>
                <br />
                <input
                  type="text"
                  value={companyName}
                  disabled
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label className="">Company Code</label>
                <br />
                <input
                  type="text"
                  value={companyCode}
                  disabled
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            </div>
            <div className="laptop:flex laptop:justify-center laptop:items-center">
            <button
              disabled={isSendingLink}
                onClick={sendPasswordLink}
                className="bg-deepBlue font-bold text-white px-4 py-2 rounded-md mt-4"
              >
              {isSendingLink?"Sending Link":"Send Link"}
              </button>  
            </div>

          </form>
        </div>
      </div>
      <div className="bg-white mt-[-80px]">
        <SingleAdminSideBar />
      </div>
      <>
        {status && (
          <Modal status={status} setStatus={setStatus} message={modalMessage} />
        )}
      </>
    </>
  );
};

export default SingleAdmin;
