import axios from "axios";
import React, { useEffect, useState } from "react";
import { LuBell } from "react-icons/lu";
import { RxCross2 } from "react-icons/rx";
import { jwtDecode } from "jwt-decode"; // Import jwtDecode
import moment from "moment"; // Import moment.js for time difference
import { useSocket } from "../SocketContext/SocketContext";

const Notification = () => {
  const [messages, setMessages] = useState([]);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(true); // State for loading spinner
  const socket = useSocket();

  let getLatestMessages = async () => {
    try {
      setLoading(true);

      // Decode JWT token to get the user role and ID
      const token = localStorage.getItem("token");
      const decodedUser = jwtDecode(token);

      let userId =
        decodedUser.role === "Admin" ? decodedUser.id : decodedUser.createdBy;
      setUserId(userId);

      // Fetch messages from the API
      const response = await axios.get(
        "https://api.triphog.net/api/v1/chat/getmymessages",
        {
          headers: { Authorization: token },
        }
      );

      // Filter messages where senderId is not equal to userId
      let filteredMessages = response.data.allTimeMessages.filter(
        (message) => message.senderId !== userId
      );

      // Sort messages by most recent based on addedON timestamp
      filteredMessages.sort((a, b) => new Date(b.addedON) - new Date(a.addedON));

      // Get the latest 10 messages
      setMessages(filteredMessages.slice(0, 10));
    } catch (e) {
      console.log("Error While Getting Notifications List");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLatestMessages();
    if (socket) {
      socket.on("new-notification", (notification) => {
        getLatestMessages();
      });
    socket.on("reload-notifications",()=>{
      console.log("Reload Notifications")
      getLatestMessages()
    })
    }
  }, [socket]);

  // Function to get the time difference
  const getTimeDifference = (date) => {
    return moment(date).fromNow(); // Use moment.js to get the time difference
  };

  return (
    <div className="bg-white rounded-[20px] p-[40px] mt-[20px] laptop:mr-[30px]">
      <div className="bg-[#EBECFF] rounded-2xl mx-auto w-[80%] p-5 flex items-center justify-between"></div>

      <h2 className="my-7 text-lap_c font-bold">Notifications</h2>

      {loading ? (
        <div className="text-center my-10">
          <p>Loading...</p>
        </div>
      ) : (
        <div className="my-2">
          {messages.length > 0 ? (
            messages.map((message, index) => (
              <div
                key={index}
                className={`my-4 ${
                  message.isRead
                    ? "bg-white" // White background for read messages
                    : "bg-[#EBECFF]" // Unread messages keep the default color
                } rounded-lg p-5 shadow-md`}
              >
                <div className="flex items-center gap-5 justify-between">
                  <div className="flex items-center gap-5">
                    {/* Avatar with senderName[0] */}
                    <div className="h-[60px] w-[60px] rounded-full bg-[#6C6685] flex items-center justify-center">
                      <span className="text-white font-bold text-xl">
                        {message.senderName.charAt(0).toUpperCase()}
                      </span>
                    </div>

                    <div>
                      <h2 className="text-[#222222] text-lap_b font-bold">
                        {/* Conditional Rendering */}
                        {message.text.length > 0 ? (
                          <>
                            {message.senderName} sent you this message:{" "}
                            {message.text}
                          </>
                        ) : message.mediaUrl.length > 0 ? (
                          <>
                            {message.mediaType === "image"
                              ? `${message.senderName} has sent you an image`
                              : message.mediaType === "video"
                              ? `${message.senderName} has sent you a video`
                              : `${message.senderName} has sent you a file`}
                          </>
                        ) : (
                          `${message.senderName} has sent you a notification`
                        )}
                      </h2>
                      <h4 className="text-[#717171] text-lap_b">
                        {getTimeDifference(new Date(message.addedON))}
                      </h4>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            ))
          ) : (
            <p>No notifications available</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Notification;
