import { IoArrowBackOutline } from "react-icons/io5";
import { useAddNewAdminContext } from "../../../../Providers/SuperAdmin/AddNewAdminProvider";
import { MdLinkedCamera } from "react-icons/md";

import { Link } from "react-router-dom";
import AdminSideBar from "./AddNewAdminSideBar";
import Modal from "../../../Modal";
import { useEffect, useReducer, useRef, useState } from "react";
import axios from "axios";
import { CircularProgress } from "@mui/material";

const Index = () => {
  const {
    setFirstName,
    setLastName,
    setEmail,
    setPhoneNumber,
    setCompanyName,
    setCompanyCode,
    setSubmit,
    photo,
    setPhoto,
    loading,
    status,
    setStatus,
    message,
  } = useAddNewAdminContext();
  let photoInputRef=useRef()
  const[noOfAdmins,setNofOfAdmins]=useState()
  const[code,setCode]=useState("")
  let getNoOfAdmins=async()=>{
    let response=await axios.get("https://api.triphog.net/api/v1/superadmin/admin")
    console.log(response.data)
    setNofOfAdmins(response.data.data.length)
  }
  useEffect(()=>{
    getNoOfAdmins()

  },[])
  const generateCompanyCode = (name) => {
    if(name.length==0)
    {
      setCode("")
    }
 else  if(name.includes(" "))
    {
      let Name=""

      let words=name.split(" ")
      console.log("Splited Words",words)
      for(let word of words)
      {
      Name=  Name.concat(word.at(0))
      }
      setCode(Name+"0000"+(noOfAdmins+1).toString())
      setCompanyCode(Name+"0000"+(noOfAdmins+1).toString())
    }
    else
    {
      setCode(name.at(0)+"0000"+(noOfAdmins+1).toString())
      setCompanyCode(name.at(0)+"0000"+(noOfAdmins+1).toString())

    }
    

  };
  return (
    <>
      <div className="laptop:h-screen">
        <div className="bg-white rounded-[20px] p-[30px] mt-[20px] laptop:mr-[30px]">
          <Link to={"/superadmin/subscribers"}>
            <div className="flex items-center gap-2 laptop:text-lap_d text-m_c font-[600]">
              <IoArrowBackOutline />
              <h2 className="laptop:text-lap_c text-m_c">Add new Admin</h2>
            </div>
          </Link>
          <div class="w-full rounded-sm  bg-cover bg-center bg-no-repeat items-center">
          
          
            <label
              for="upload_profile"
              style={{
                backgroundImage: `url(http://127.0.0.1:8000/uploads/)`,
              }}
              class="mx-auto cursor-pointer flex justify-center w-[141px] h-[141px] bg-blue-300/20 rounded-full bg-cover bg-center bg-no-repeat"
            >
                  <input
                ref={photoInputRef}
                  type="file"
                
                  hidden
                  onChange={(event) => {
                    const file = event.target.files[0]; // Get the selected file
                    setPhoto(file); // Set the file to the photo state
                  }}
                />
              {photo!=null?<label for="upload_profile" onClick={()=>{
                photoInputRef.current.click()
              }}><img className="rounded-full" style={{height:"100%",width:"100%"}} src={URL.createObjectURL(photo)}/></label>:              <div class="bg-white/90 rounded-full w-6 h-6 text-center ml-28 mt-4">
                <input
                  type="file"
                  name="photo"
                  id="upload_profile"
                  hidden
                  onChange={(event) => {
                    const file = event.target.files[0]; // Get the selected file
                    setPhoto(file); // Set the file to the photo state
                  }}
                />
                
                <label for="upload_profile">
                  <span className="text-[25px] text-deepBlue">
                    <MdLinkedCamera />
                  </span>
                </label>

              </div> 
}
            </label>
          </div>
          <form
            className="text-darkgray laptop:text-lap_b text-[30px]"
            onSubmit={(event) => {
              event.preventDefault();
            }}
          >
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%] ">
                <label className="">First Name</label>
                <br />
                <input
                  type="text"
                  placeholder="Enter your First Name"
                  onChange={(event) => {
                    setFirstName(event.target.value);
                  }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px]">
                <label>Last Name</label>
                <br />
                <input
                  type="text"
                  placeholder="Enter your Last Name"
                  onChange={(event) => {
                    setLastName(event.target.value);
                  }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            </div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%] ">
                <label className="">Your Email</label>
                <br />
                <input
                  type="text"
                  placeholder="jaskolski.brent@gmail.com"
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Phone Number</label>
                <br />
                <input
                  type="text"
                  placeholder="546-933-2772"
                  onChange={(event) => {
                    setPhoneNumber(event.target.value);
                  }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            </div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%] ">
                <label className="">Company Name</label>
                <br />
                <input
                  type="text"
                  placeholder="Company Name"
                  onChange={(event) => {
                    
                    generateCompanyCode(event.target.value)
                    setCompanyName(event.target.value);
                  }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%] ">
                <label className="">Company Code</label>
                <br />
                <input
                value={code}
                  type="text"
                  
                  disabled
                  placeholder="Company Code"
                  
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            </div>
            <div className="flex justify-center items-center">
             {loading?<button  className="bg-deepBlue text-white laptop:text-lap_c text-m_b cursor-pointer rounded-md py-[7px] px-[40px] mt-[25px]" disabled><CircularProgress style={{height:"15px",width:"15px",color:"white"}} /></button>:<input
                onClick={() => {
                  setSubmit(true);
                }}
                type="submit"
                className="bg-deepBlue text-white laptop:text-lap_c text-m_b cursor-pointer rounded-md py-[7px] px-[40px] mt-[25px]"
              />} 
            </div>
          </form>
        </div>
      </div>
      <div className="mt-[-100px] bg-[white]">
        <AdminSideBar />
      </div>
      {status && (
        <Modal status={status} setStatus={setStatus} message={message} />
      )}
    </>
  );
};

export default Index;
