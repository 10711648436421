import axios from "axios";
import { useEffect, useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
// import { useAddNewAdminContext } from "../../../../Providers/SuperAdmin/AddNewAdminProvider";
import { MdLinkedCamera } from "react-icons/md";
import { toast,ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { jwtDecode } from "jwt-decode";
// import AdminSideBar from "./AddNewAdminSideBar";
// import Modal from "../../../Modal";

const EditProfile = () => {
  const token=localStorage.getItem("token")
  const decodedUser=jwtDecode(token)
  const[firstName,setFirstName]=useState("")
  const[lastName,setLastName]=useState("")
  const[email,setEmail]=useState("")
  const[phoneNumber,setPhoneNumber]=useState("")
  const[companyCode,setCompanyCode]=useState("")
  const[companyName,setCompanyName]=useState("")
  const[loading,setLoading]=useState(false)
  let getAdminById=async()=>{
   let response= await axios.get(`https://api.triphog.net/api/v1/superadmin/admin/getbyId/${localStorage.getItem("token")}`)
   console.log(response.data)
   setFirstName(response.data.data.firstName)
   setLastName(response.data.data.lastName)
   setEmail(response.data.data.email)
   setPhoneNumber(response.data.data.phoneNumber)
   setCompanyName(response.data.data.companyName)
   setCompanyCode(response.data.data.companyCode)
  


  }
  let updateAdmin=async()=>{
    setLoading(true)
    let response=await axios.put(`https://api.triphog.net/api/v1/superadmin/admin/update/${localStorage.getItem("adminId")}`,{firstName,lastName,email,phoneNumber,companyCode,companyName})
    if(response.data.status=="success")
    {
      toast.success("Sucessfully Updated!",{position:"top-center"})
      setLoading(false)
    }
    else{
      toast.error("There Was An Error While Updating!",{position:"top-center"})
      setLoading(false)
    }
    console.log(response.data)
  }
  useEffect(()=>{
    if(token && decodedUser.role=="Admin")
    {
    getAdminById()
    }

  },[])
  if(!token)
  {
    return(<div className="flex flex-col justify-center items-center">
      PLEASE LOGIN TO CONTINUE
    </div>)
  }
  else if(decodedUser.role=="User")
  {
    return(
      <div className="flex flex-col justify-center items-center">
        YOU CANNOT ACCESS THIS PAGE
      </div>
    )
  }
  else{
  return (
    <>
      {/* <div className="laptop:h-screen"> */}
      <div className="bg-white rounded-[20px] p-[30px] mt-[20px] laptop:mr-[30px]">
      <ToastContainer/>
        <Link to={"/admin"}>
          <h2 className="laptop:text-lap_c text-m_c text-center font-semibold my-4">
            {firstName+" "+lastName} Profile
          </h2>
        </Link>
        {/* <div class="w-full rounded-sm  bg-cover bg-center bg-no-repeat items-center">
          <label
            for="upload_profile"
            style={{
              backgroundImage: `url(http://127.0.0.1:8000/uploads/)`,
            }}
            class="mx-auto cursor-pointer flex justify-center w-[141px] h-[141px] bg-blue-300/20 rounded-full bg-cover bg-center bg-no-repeat"
          >
            <div class="bg-white/90 rounded-full w-6 h-6 text-center ml-28 mt-4">
              <input
                type="file"
                name="photo"
                id="upload_profile"
                hidden
                // onChange={(event) => {
                //   const file = event.target.files[0]; // Get the selected file
                //   setPhoto(file); // Set the file to the photo state
                // }}
              />
              <label for="upload_profile">
                <span className="text-[25px] text-deepBlue">
                  <MdLinkedCamera />
                </span>
              </label>
            </div>
          </label>
        </div> */}
        <form
          className="text-darkgray laptop:text-lap_b text-[30px]"
          onSubmit={(event) => {
            event.preventDefault();
          }}
        >
          <div className="laptop:flex gap-16 laptop:my-[16px]">
            <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%] ">
              <label className="">First Name</label>
              <br />
              <input
                type="text"
                placeholder="Jenny"
                value={firstName}
                 onChange={(event) => {
                   setFirstName(event.target.value);
                 }}
                className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
              />
            </div>
            <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px]">
              <label>Last Name</label>
              <br />
              <input
                type="text"
                value={lastName}
                placeholder="Wilson "
                onChange={(event) => {
                  setLastName(event.target.value);
                }}
                className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
              />
            </div>
          </div>
          <div className="laptop:flex gap-16 laptop:my-[16px]">
            <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%] ">
              <label className="">Your Email</label>
              <br />
              <input
                type="text"
                placeholder="jenny@example.com"
                value={email}
                disabled
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
              />
            </div>
            <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
              <label>Phone Number</label>
              <br />
              <input
                type="text"
                placeholder="546-933-2772"
                value={phoneNumber}
                onChange={(event) => {
                  setPhoneNumber(event.target.value);
                }}
                className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
              />
            </div>
          </div>
          <div className="laptop:flex gap-16 laptop:my-[16px]">
            <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%] ">
              <label className="">Company Name</label>
              <br />
              <input
              disabled

                type="text"
                placeholder="Company Name"
                value={companyName}
                onChange={(event) => {
                  setCompanyName(event.target.value);
                }}
                className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
              />
            </div>
            
          </div>
          <div className="laptop:flex gap-16 laptop:my-[16px]">
            <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
              <label>Company Code</label>
              <br />
              <input
              value={companyCode}
              disabled
                type="text"
                placeholder="RWL1000"
                onChange={(event) => {
                  setCompanyCode(event.target.value);
                }}
                className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
              />
            </div>
          </div>

          <div className="flex justify-center items-center">
            {loading?<div className="bg-deepBlue text-white laptop:text-lap_c text-m_b cursor-pointer rounded-md py-[7px] px-[40px] mt-[25px]"><CircularProgress style={{color:"white",height:"16px",width:"16px"}} thickness={5}/></div>: <input
              onClick={(e) => {
                e.preventDefault()
                updateAdmin()
                
              }}
              type="submit"
              className="bg-deepBlue text-white laptop:text-lap_c text-m_b cursor-pointer rounded-md py-[7px] px-[40px] mt-[25px]"
            />}
           
          </div>
        </form>
      </div>
      {/* </div> */}

      {/* {status && (
        <Modal status={status} setStatus={setStatus} message={message} />
      )} */}
    </>
  );
}
};

export default EditProfile;
