import React, { useEffect, useState } from "react";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { IoNotificationsOutline } from "react-icons/io5";
import { TbRectangleFilled } from "react-icons/tb";
import { CiCalendarDate } from "react-icons/ci";
import { FaCarSide } from "react-icons/fa6";
import axios from "axios";
import { Unarchive } from "@mui/icons-material";
const DriverSideBar = (props) => {
  const[totalDrivers,setTotalDrivers]=useState([])
  const[availableDrivers,setAvailableDrivers]=useState([])
  const[unAvailableDrivers,setUnAvailableDrivers]=useState([])
  const[onRoute,setOnRouteDrivers]=useState([])
  let getAndSetDrivers=async()=>{
    const token=localStorage.getItem("token")
    let allDriversResponse=await axios.get("https://api.triphog.net/api/v1/driver/getdrivers",{headers:{'Authorization':token}})
    let tripsResponse=await axios.get("https://api.triphog.net/api/v1/trip/gettrips",{headers:{'Authorization':token}})
    console.log(tripsResponse.data.trips)
    let pickUpDate=new Date().toDateString()
    let pickUpTime=new Date().toTimeString()
    let appointmentTime=""
    console.log(pickUpDate)
    console.log(pickUpTime)
    let availableDriversResponse=await axios.get("https://api.triphog.net/api/v1/driver/getavailabledrivers",{params:{pickUpDate,pickUpTime,appointmentTime},headers:{'Authorization':token}})

    let unAvailable=allDriversResponse.data.drivers.length-availableDriversResponse.data.length
    console.log(unAvailable)
    console.log(allDriversResponse.data.drivers.length)
    let onRoute=[]
    
    for(let driver of allDriversResponse.data.drivers)
      {
        let filteredTrips=tripsResponse.data.trips.filter((trip)=>{
          return(trip.driverRef==driver._id && trip.status=="On Route")
        })
        if(filteredTrips.length>0)
        {
          onRoute.push(driver)
        }
      }
      console.log("On Route Drivers",onRoute.length)
      setTotalDrivers(allDriversResponse.data.drivers.length)
      setAvailableDrivers(allDriversResponse.data.drivers.length-onRoute.length)
      
      setOnRouteDrivers(onRoute.length) 
  }
  useEffect(()=>{
    getAndSetDrivers()
  },[props])
  return (
    <div className="laptop:grid hidden laptop:sticky laptop:top-0 laptop:left-0 laptop:right-0 p-[25px] bg-white ">
      <div className="w-[100%]">
        <div className="flex float-right text-lap_e cursor-pointer">
          <IoNotificationsOutline />
        </div>
        <h2 className="text-lap_c mt-[60px] font-[600]">DRIVERS</h2>
        <h2 className="text-lap_d font-[700] text-deepBlue">{totalDrivers}</h2>
        <div className="grid grid-cols-2 items-center my-[20px] gap-[10%]">
          <div>
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#409261] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{availableDrivers}</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">Available</span>
            </div>
            
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#E42346] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{onRoute}</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">On Route</span>
            </div>
          </div>
          
        </div>
        
      </div>
    </div>
  );
};

export default DriverSideBar;
