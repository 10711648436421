import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
const PaymentSuccess = () => {
 let navigate=useNavigate()
  let updatePaymentHistory=async()=>{
    let response=await axios.put("https://api.triphog.net/api/v1/admin/update-payment-history",{},{headers:{"Authorization":localStorage.getItem("token")}})
    console.log(response.data)
    if(response.data.success){
      console.log("Navigating To -2")
      navigate(-3)
    }
  }
  useEffect(()=>{
    updatePaymentHistory()
  },[])
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      textAlign="center"
    >
      <Typography variant="h4" color="green">
        Payment Successful! 🎉
      </Typography>
    </Box>
  );
};

export default PaymentSuccess;
