import { Box,Card } from "@mui/material"
import { useNavigate } from "react-router-dom"
function SuperAdminSettings(){
    let navigate=useNavigate()
    return(<div onClick={()=>{
        navigate("/superadmin/settings/changepassword")
    }} className="border rounded-lg cursor-pointer p-[20px] m-auto mt-[20px] w-[300px] bg-slate">
            Change Password
          </div>)
}
export default SuperAdminSettings